import { createForm } from "effector-react-form";
import {
  combine,
  Effect,
  attach,
  sample,
  createStore,
  Store,
  createEvent,
} from "effector";
import { and, every } from "patronum";
import { number, object, string } from "yup";

import { createValidator, formField, formFieldChanged } from "shared/lib/form";
import { requiredFieldValidationError } from "shared/config/error-text";
import {
  createReservationsDateTimeModel,
  getReservationWithClientPriceFx,
  ReservationPrice,
} from "entities/reservations";
import { $docksList } from "entities/docks";
import { Boat, Client } from "shared/api/types";
import { createOpenAbleState } from "shared/lib/effector-openable-state";
import { createGate } from "effector-react";

type PriceInfo = ReservationPrice;
export const $priceInfo = createStore<PriceInfo | null>(null);

const numberSchema = number()
  .required(requiredFieldValidationError)
  .nullable()
  .typeError(" ");

export const managerReservationValidator = createValidator(
  object({
    dock: string().required(requiredFieldValidationError).typeError(" "),
    loa: numberSchema.min(0, " ").max(100, "LOA must be 100 or less."),
    date: string().required(requiredFieldValidationError).typeError(" "),
    time: object().required(requiredFieldValidationError).typeError(" "),
    duration: numberSchema,
    comment: string(),
  })
);

interface FormParams {
  onSubmit: Effect<any, any>;
  getAvailableItemsFx: Effect<
    { hours: number; monthStartDate: string },
    Record<string, { from: string; to: string }[]>
  >;
  $client: Store<Client | null>;
  $boat: Store<Boat | null>;
  $addons: Store<string[] | null>;
  $isCharter: Store<boolean | null>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createManagerReservationForm(params: FormParams) {
  const reservationForm = createForm<{
    loa: number | null;
    dock: string;
    date: string;
    time: {
      from: string;
      to: string;
    } | null;
    duration: number | null;
    isCharter: boolean;
    comment: string;
    client: string | null;
    boat: string | null;
    isSuperOverride: boolean;
    dockAddons: string[];
  }>({
    validate: managerReservationValidator,
    initialValues: {
      loa: null,
      dock: "",
      date: "",
      time: null,
      duration: null,
      isCharter: false,
      comment: "",
      client: null,
      boat: null,
      isSuperOverride: false,
      dockAddons: [],
    },
    onSubmit: async () => {
      await formSubmitFx();
    },
  });

  const formGate = createGate(reservationForm);
  const boatSelected = createEvent();
  const superOverrideNotConfirmed = createEvent();
  const $isLOADisabled = createStore(false);

  const [loaChangeModal, loaChangeModalActions] = createOpenAbleState();
  const [superOverrideModal, superOverrideModalActions] = createOpenAbleState();

  const $duration = formField(reservationForm, "duration");
  const $selectedDate = formField(reservationForm, "date");
  const $selectedTime = formField(reservationForm, "time");
  const $loa = formField(reservationForm, "loa");
  const $dock = formField(reservationForm, "dock");
  const $client = formField(reservationForm, "client");
  const $boat = formField(reservationForm, "boat");
  const $isCharter = formField(reservationForm, "isCharter");
  const $comment = formField(reservationForm, "comment");
  const $isSuperOverride = formField(reservationForm, "isSuperOverride");
  const $dockAddons = formField(reservationForm, "dockAddons");

  const $selectedDock = combine(
    $docksList,
    $dock,
    (docksList, id) => docksList.find((dock) => dock["@id"] === id) ?? null
  );
  const $boatInfo = combine($loa, (loa) => (loa ? { loa } : null));

  const dateTimeModel = createReservationsDateTimeModel({
    $dock: $selectedDock,
    $boat: $boatInfo,
    $duration: $duration,
    $selectedDate,
    $selectedTime,
    $isSuperOverride,
    timeChanged: formFieldChanged(reservationForm, "time"),
    getAvailableItemsFx: params.getAvailableItemsFx,
    getAvailableItemsTrigger: params.onSubmit.fail,
  });

  const $priceRequestData = combine({
    dock: $dock,
    loa: $loa,
    timeFrom: dateTimeModel.$timeFrom,
    timeTo: dateTimeModel.$timeTo,
    dockAddons: $dockAddons,
    isCharter: $isCharter,
  });

  const $isSubmitEnabled = every([$dock, $client, $boat, $priceInfo], Boolean);

  const getPriceFx = attach({
    effect: getReservationWithClientPriceFx,
  });

  const loaChanged = sample({
    clock: params.$boat,
    source: $loa,
    filter: (currentLOA, boat) => !!currentLOA && boat?.loa !== currentLOA,
  });

  sample({
    clock: $loa,
    source: reservationForm.$values,
    fn: (values) => ({ ...values, date: null, time: null }),
    target: reservationForm.setValues,
  });

  sample({
    clock: combine({ $dock, $loa, $duration }),
    target: dateTimeModel.$availableDates.reset(),
  });

  sample({
    clock: $priceRequestData,
    filter: and($loa, dateTimeModel.$timeTo, dateTimeModel.$timeFrom, $dock),
    target: getPriceFx,
  });

  sample({
    source: params.$client,
    fn: (client) => ({ field: "client", value: client["@id"] }),
    target: reservationForm.setValue,
  });

  sample({
    source: params.$boat,
    fn: (boat) => ({ field: "boat", value: boat["@id"] }),
    target: reservationForm.setValue,
  });

  sample({
    source: params.$boat,
    fn: (boat) => ({ field: "loa", value: boat.loa }),
    target: reservationForm.setValue,
  });

  sample({
    source: params.$addons,
    fn: (addons) => ({ field: "dockAddons", value: addons }),
    target: reservationForm.setValue,
  });

  sample({
    source: params.$isCharter,
    fn: (isCharter) => ({ field: "isCharter", value: isCharter }),
    target: reservationForm.setValue,
  });

  sample({
    source: params.$boat.map(Boolean),
    target: boatSelected,
  });

  sample({
    clock: loaChanged,
    filter: and(formGate.status),
    target: loaChangeModalActions.open,
  });

  sample({
    source: $isSuperOverride,
    filter: $isSuperOverride,
    target: superOverrideModalActions.open,
  });

  sample({
    clock: superOverrideNotConfirmed,
    fn: () => ({ field: "isSuperOverride", value: false }),
    target: reservationForm.setValue,
  });

  sample({
    clock: superOverrideNotConfirmed,
    target: superOverrideModalActions.close,
  });

  $isLOADisabled.on(boatSelected, () => true);

  $priceInfo
    .reset([getPriceFx, formGate.close])
    .on(getPriceFx.doneData, (_, priceInfo) => priceInfo);

  const formSubmitFx = attach({
    effect: params.onSubmit,
    source: {
      dock: $dock,
      client: $client,
      boat: $boat,
      timeFrom: dateTimeModel.$timeFrom,
      timeTo: dateTimeModel.$timeTo,
      comment: $comment,
      isCharter: $isCharter,
      isSuperOverride: $isSuperOverride,
      dockAddons: $dockAddons,
    },
  });

  return {
    form: reservationForm,
    formGate,
    $isLOADisabled,
    loaChangeModal,
    loaChangeModalActions,
    superOverrideModal,
    superOverrideModalActions,
    superOverrideNotConfirmed,
    $isSubmitEnabled,
    $priceInfo,
    dateTimeModel,
    $selectedDock,
  };
}
