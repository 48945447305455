import { createGate } from "effector-react";
import { attach, combine, createEvent, createStore } from "effector";

import { getClientCollection, getListFromApi } from "shared/api";
import { pagination, spreadListDataData } from "shared/lib/effector-paginatoin";
import { Client } from "shared/api/types";
import { newCustomerCreated } from "features/new-customer";

interface Filters {
  itemsPerPage: number;
  keyword: string | null;
}

export const pageGate = createGate();

export const $clientsList = createStore<Client[]>([]);
export const $clientsTotalCount = createStore(0);

export const $currentPage = createStore(1);
export const $itemsPerPage = createStore(10);
export const $searchQuery = createStore<string | null>(null);

export const pageChanged = createEvent<number>();
export const itemsPerPageChanged = createEvent<number>();
export const searchQueryChanged = createEvent<string>();

const $filters = combine({
  itemsPerPage: $itemsPerPage,
  keyword: $searchQuery,
});

$currentPage.on(pageChanged, (_, page) => page);
$searchQuery.on(searchQueryChanged, (_, value) => value).reset(pageGate.close);
$itemsPerPage.on(itemsPerPageChanged, (_, value) => value);

const getClientCollectionFx = attach({
  // @ts-ignore
  effect: getListFromApi(getClientCollection),
  mapParams: (query: Filters & { page: number }) => ({
    query,
  }),
});

spreadListDataData({
  $items: $clientsList,
  $totalCount: $clientsTotalCount,
  effect: getClientCollectionFx,
});

pagination({
  $page: $currentPage,
  $filters: $filters,
  effect: getClientCollectionFx,
  gate: pageGate,
  reloadTrigger: newCustomerCreated,
});
