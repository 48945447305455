import styled from "styled-components";
import { useGate, useStore } from "effector-react";
import { Link } from "react-router-dom";
import { Dayjs } from "dayjs";

import { Card, CardTitle } from "shared/ui/card";
import { SmallTable } from "shared/ui/small-table";
import { AddIcon, ContentPageIcon, TrashIcon } from "shared/ui/icons";
import { Table, TableActions, ActionEdit, ActionDelete } from "shared/ui/table";
import { PhoneNumber } from "shared/ui/phone-number";
import { ConfirmModal, useConfirmModal } from "shared/ui/modals";
import { mediaPhone, useMedia } from "shared/ui/styles/media";
import { LinkButton } from "shared/ui/button";
import { displayTime } from "shared/lib/dayjs-ext";
import { getDockPricesRange, convertHoursFromArrayToMap } from "entities/docks";
import { deleteDock } from "@manager-app/features/manage-docks";
import { MetaTags } from "shared/lib/metaTags";

import { Dock } from "shared/api/types";

import {
  $currentPage,
  $docksList,
  $docksTotalCount,
  $itemsPerPage,
  itemsPerPageChanged,
  pageChanged,
  pageGate,
} from "./model";

export function DocsListPage(): JSX.Element {
  useGate(pageGate);
  const docks = useStore($docksList);
  const total = useStore($docksTotalCount);
  const currentPage = useStore($currentPage);
  const rowsPerPage = useStore($itemsPerPage);

  const { isPhone } = useMedia();

  const [confirmDeleting, confirmModalProps] = useConfirmModal(
    (dock: typeof docks[number]) => deleteDock(dock.id),
    (dock) => ({
      message: `Are you sure you want to delete ${dock.name}?`,
    })
  );

  return (
    <Root as={Card}>
      <MetaTags title="Docks" />
      <Header>
        List of Docks
        <CreateDockButton as={LinkButton} to={"/docks-list/add"}>
          <Icon as={AddIcon} />
          Add Dock
        </CreateDockButton>
      </Header>
      <Table
        items={docks as any}
        // @ts-ignore
        columns={[
          {
            header: "Image",
            renderCell: (dock: Dock) => <DockImage src={dock.image.url!} />,
          },
          { header: "Dock name", renderCell: (dock: Dock) => dock.name },
          {
            header: "Place",
            renderCell: (dock: Dock) => dock.cityObject?.name ?? "",
          },
          { header: "Length", renderCell: (dock: Dock) => `${dock.length} ft` },
          {
            header: "Price",
            renderCell: (dock: Dock) => {
              const { min, max } = getDockPricesRange(dock as any);
              if (max === min) {
                return `$${max}`;
              }
              return `$${min}-${max}`;
            },
          },
          {
            header: "Email",
            renderCell: (dock: Dock) => dock.email,
          },
          {
            header: "Phone",
            renderCell: (dock: Dock) => <PhoneNumber phone={dock.phone} />,
          },
          isPhone && {
            header: "Operational hours",
            mobileAlign: "start",
            renderCell: (dock: Dock) => (
              <OperationsHoursTable hours={dock.operationalHours} />
            ),
          },
          {
            header: "Action",
            renderCell: (dock: Dock) => (
              <TableActions>
                <Link to={`/docks-list/${dock.id}/terms/edit`}>
                  <ActionEdit as={TermsActionEdit} />
                </Link>
                <Link to={`/docks-list/${dock.id}/edit`}>
                  <ActionEdit />
                </Link>
                <ActionDelete onClick={() => confirmDeleting(dock as any)} />
              </TableActions>
            ),
          },
        ].filter((column) => !!column)}
        renderCollapsedContent={(dock) => (
          <NestedTables>
            {!isPhone && <OperationsHoursTable hours={dock.operationalHours} />}
            <RestaurantsTable restaurants={dock.restaurants as any} />
          </NestedTables>
        )}
        pagination={{
          total: total,
          onPageChange: pageChanged,
          onItemsPerPageChange: itemsPerPageChanged,
          currentPage: currentPage,
          itemsPerPage: rowsPerPage,
          rowsPerPageOptions: [5, 10, 20],
        }}
      />

      <ConfirmModal
        {...confirmModalProps}
        title={"Delete dock?"}
        Icon={TrashIcon}
        confirmText="Yes, delete"
      />
    </Root>
  );
}

const TermsActionEdit = styled((props) => {
  return (
    <div className={props.className}>
      <ContentPageIcon />
    </div>
  );
})`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d4fa1;
  border-radius: 50%;
  color: #fbfcff;
  & > * {
    width: 22px;
    height: 22px;
  }
`;

const Header = styled(CardTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 36px;
  line-height: 48px;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

const CreateDockButton = styled.div`
  width: 180px;

  ${Icon} {
    margin-right: 8px;
  }
`;

const DockImage = styled.img`
  width: 64px;
`;

const Root = styled.div`
  ${Table as any} {
    ${mediaPhone} {
      margin: calc(var(--pageContentPadding) * -1);
    }
  }
`;

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function OperationsHoursTable(props: any): JSX.Element {
  const { isPhone } = useMedia();
  const hoursMap = convertHoursFromArrayToMap(props.hours);

  return isPhone ? (
    <>
      {days.map((day) => (
        <Day>
          <div>{day}</div>
          {hoursMap[day] ? (
            <TimeRange from={hoursMap[day].from} to={hoursMap[day].to} />
          ) : (
            "Closed"
          )}
        </Day>
      ))}
    </>
  ) : (
    <SmallTable
      items={[1]}
      name="Operational hours"
      columns={days.map((day) => ({
        header: day,
        renderCell: () =>
          hoursMap[day] ? (
            <TimeRange from={hoursMap[day].from} to={hoursMap[day].to} />
          ) : (
            "Closed"
          ),
      }))}
    />
  );
}

const Day = styled.div`
  display: flex;
  justify-content: space-between;
`;

function TimeRange(props: { from: Dayjs; to: Dayjs }): JSX.Element {
  return (
    <TimeRangeRoot>
      <div>{displayTime(props.from)}</div> - <div>{displayTime(props.to)}</div>
    </TimeRangeRoot>
  );
}

const TimeRangeRoot = styled.div`
  font-size: 12px;
  display: flex;
  gap: 2px;
`;

interface RestaurantsTableProps {
  restaurants: Array<{
    "@type": string;
    name: string;
    distance: string;
    url?: string;
  }>;
}

export function RestaurantsTable(
  props: RestaurantsTableProps
): JSX.Element | null {
  const { isPhone } = useMedia();
  if (props.restaurants.length === 0) {
    return null;
  }

  return isPhone ? (
    <MobileRestaurantsTable>
      <Title>Restaurants</Title>
      {props.restaurants.map((restaurant) => (
        <MobileRestaurants>
          <MobileRestaurant>
            <RestaurantsType>{restaurant["@type"]} — </RestaurantsType>
            <RestaurantsName>
              {restaurant.url ? (
                <a href={restaurant.url} target="_blank">
                  {restaurant.name}
                </a>
              ) : (
                restaurant.name
              )}
            </RestaurantsName>
            <RestaurantsDistance>{restaurant.distance} mi</RestaurantsDistance>
          </MobileRestaurant>
        </MobileRestaurants>
      ))}
    </MobileRestaurantsTable>
  ) : (
    <Restaurants
      as={SmallTable}
      items={props.restaurants}
      name="Restaurants"
      columns={[
        {
          header: "Type",
          renderCell: (restaurant: any) => restaurant["@type"],
        },
        {
          header: "Restaurant name",
          renderCell: (restaurant: any) => {
            if (restaurant.url) {
              return (
                <a href={restaurant.url} target="_blank">
                  {restaurant.name}
                </a>
              );
            } else return restaurant.name;
          },
        },
        {
          header: "Distance",
          renderCell: (restaurant: any) => `${restaurant.distance} mi`,
        },
      ]}
    />
  );
}

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
`;

const RestaurantsType = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #969899;
  white-space: nowrap;
`;

const RestaurantsName = styled.div``;

const RestaurantsDistance = styled.div`
  flex: 1;
  text-align: right;
  white-space: nowrap;
  align-self: center;
`;

const MobileRestaurant = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
`;

const MobileRestaurants = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`;

const MobileRestaurantsTable = styled.div`
  ${Title} {
    margin-bottom: 12px;
  }
`;

const Restaurants = styled.div`
  width: 780px;
`;

const NestedTables = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`;
