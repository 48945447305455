import { useStore } from "effector-react";
import { useHistory } from "react-router";

import {
  $formSubmittedEmail,
  ForgotPasswordForm,
  ForgotPasswordFormSuccessModal,
} from "features/password-recovery";

import { AuthPageTemplate } from "shared/ui/templates/auth-page-template/auth-page-template";
import { MetaTags } from "shared/lib/metaTags";

export function ForgotPasswordPage(): JSX.Element {
  const formSubmittedEmail = useStore($formSubmittedEmail);
  const history = useHistory();
  return (
    <>
      <MetaTags title="Forgot password" />
      <AuthPageTemplate
        header={"Welcome to dock management"}
        renderContent={() => <ForgotPasswordForm />}
      />

      <ForgotPasswordFormSuccessModal
        isOpened={!!formSubmittedEmail}
        onClose={() => history.push("/")}
      />
    </>
  );
}
