import styled from "styled-components";
import { useGate, useStoreMap } from "effector-react";

import {
  createFormField,
  Select,
  SelectProps,
} from "shared/ui/ecosystems/forms";

import { $restaurantsTypesList, Gate } from "../model";

type Props = Omit<SelectProps<string>, "options">;

export function RestaurantTypeSelectorComponent(props: Props): JSX.Element {
  useGate(Gate);
  const options = useStoreMap($restaurantsTypesList, (types) =>
    types.map((type) => ({
      label: type.name,
      value: type["@id"],
    }))
  );

  return <Select {...props} options={options} />;
}

export const RestaurantTypeSelector = styled(RestaurantTypeSelectorComponent)``;

export const RestaurantTypeField = createFormField(RestaurantTypeSelector);
