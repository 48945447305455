import { attach, createEffect, createStore, sample } from "effector";
import { createGate } from "effector-react";

import { createRouteParamStore } from "shared/lib/effector-router-params";
import {
  fromApi,
  getClientItem,
  getListFromApi,
  getReservationCollection,
} from "shared/api";
import { Client } from "shared/api/types";

import { suspiciousSet } from "@manager-app/features/manage-suspicious-users";

import { $modalClient, commentEdited } from "./edit-comment/model";

export const clientPageGate = createGate();

const $clientId = createRouteParamStore({
  name: "customerId",
  gate: clientPageGate,
});

export const getClientsReservationsFx = attach({
  // @ts-ignore
  effect: getListFromApi(getReservationCollection),
  source: $clientId,
  mapParams: (query, clientId) => ({
    query: {
      ...query,
      client: clientId,
      pagination: false,
    },
  }),
});

export const $client = createStore<Client | null>(null);

const getClientInfoFx = attach({
  effect: createEffect(fromApi(getClientItem)),
  source: $clientId,
  mapParams: (_, clientId) => ({ path: { id: clientId as string } }),
});

$client
  .on(getClientInfoFx.doneData, (_, data) => data as Client)
  .on(suspiciousSet, (state, data) => ({ ...state, ...data }))
  .reset(clientPageGate.close);

sample({
  // @ts-ignore
  clock: $client,
  source: $clientId,
  fn: (clientId, data) =>
    data && {
      id: clientId,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      comment: data.comment ?? "",
    },
  target: $modalClient,
});

sample({
  clock: [clientPageGate.open, suspiciousSet],
  target: [getClientInfoFx, getClientsReservationsFx],
});

sample({
  clock: commentEdited,
  target: getClientInfoFx,
});
