import { attach, createStore, sample, Store } from "effector";
import { createGate } from "effector-react";
import { createForm } from "effector-react-form";

import { createRouteParamStore } from "shared/lib/effector-router-params";
import {
  Dock,
  getDockById,
  submitDockTermsAndConditionsFx,
} from "entities/docks";

export const pageGate = createGate();

const $dockId = createRouteParamStore({
  name: "dockId",
  gate: pageGate,
});
export const $dock = createStore<Dock | null>(null);

const getDockFx = attach({ effect: getDockById });

$dock.on(getDockFx.doneData, (_, dock) => dock);

export const form = createForm({
  initialValues: {
    terms: "",
  },
  onSubmit: async ({ values }) => {
    await submitFormFx(values.terms);
  },
});

const submitFormFx = attach({
  effect: submitDockTermsAndConditionsFx,
  source: $dockId as Store<string>,
  mapParams: (terms: string, dockId) => ({
    dockId,
    terms,
  }),
});

sample({
  clock: pageGate.open,
  source: $dockId as Store<string>,
  target: getDockFx,
});

sample({
  clock: getDockFx.doneData,
  target: form.setValue.prepend((dock: Dock) => ({
    field: "terms",
    value: dock.terms,
  })),
});

export const $isFormSubmitting = submitFormFx.pending;
