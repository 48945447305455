import { createEvent, createStore, createEffect } from "effector";

import { getManagerMe, fromApi } from "shared/api";

export interface Manager {
  email: string;
  company?: string;
  firstName: string;
  lastName: string;
  createdAt?: string;
  updatedAt?: string;
}

export const logout = createEvent();

export const getManagerDataFx = createEffect(fromApi(getManagerMe));

export const $viewer = createStore<Manager | null>(null);

$viewer
  .on(getManagerDataFx.doneData, (_, data) => data as Manager)
  .reset(logout);
