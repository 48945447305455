import { createForm } from "effector-react-form";
import { createGate } from "effector-react";
import { boolean, number, object, string } from "yup";
import { every, reset } from "patronum";
import { attach, combine, createEffect, createStore, sample } from "effector";

import { postBoatCollectionByManager } from "@manager-app/shared/api/boats";
import { createValidator } from "shared/lib/form";
import { requiredFieldValidationError } from "shared/config/error-text";
import {
  fromApi,
  getBoatsByManufacturerModelYear,
  getManufacturers,
  getModelsByManufacturer,
  getYearsByManufacturerModel,
} from "shared/api";
import { Boat } from "shared/api/types";

export const $newBoat = createStore<Boat | null>(null);

const numberSchema = number()
  .required(requiredFieldValidationError)
  .nullable()
  .typeError(" ");

const nameSchema = string()
  .nullable()
  .required(requiredFieldValidationError)
  .typeError(" ");

const boatFormSchema = object({
  name: nameSchema,
  manufacturer: nameSchema,
  model: nameSchema,
  year: numberSchema.typeError("Year must be a number"),
  loa: numberSchema.min(0, " ").max(100, "LOA must be 100 or less."),
  beam: numberSchema.min(0, " ").max(100, "Beam must be 100 or less."),
  draft: number().nullable().typeError(" "),
  height: number().nullable().typeError(" "),
  isMain: boolean(),
});

type BoatType = {
  name: string;
  manufacturer: string;
  model: string;
  year: string;
  loa: number;
  beam: number;
  draft: number;
  height: number;
  isMain: boolean;
};

export const addBoatForm = createForm({
  validate: createValidator(boatFormSchema),
  onSubmit: ({ values }) => createBoatFx(values),
  // @ts-ignore
  initialValues: {
    name: null,
    manufacturer: null,
    model: null,
    year: null,
    loa: null,
    beam: null,
    draft: null,
    height: null,
    isMain: false,
  } as BoatType,
});

type ManufacturerName = string;
type ModelName = string;
type Year = string;

type ManufacturerId = number;

export const Gate = createGate();

export const $manufacturersList = createStore<ManufacturerName[]>([]);
const $manufacturersMap = createStore<Record<ManufacturerName, ManufacturerId>>(
  {}
);

const $modelsByManufacturerId = createStore<
  Record<ManufacturerId, ModelName[]>
>({});
const $modelsMap = createStore<Record<string, number>>({});

const $yearsByModelsAndManufacturerIds = createStore<Record<string, Year[]>>(
  {}
);

const $selectedManufacturer = addBoatForm.$values.map(
  (values) => values.manufacturer
);
const $selectedModel = addBoatForm.$values.map((values) => values.model);
const $selectedYear = addBoatForm.$values.map((values) => values.year);

const $selectedManufacturerId = combine(
  $selectedManufacturer,
  $manufacturersMap,
  (selectedManufacturer, manufacturerMap) =>
    manufacturerMap[toKey(selectedManufacturer)] ?? null
);

const $selectedModelId = combine(
  $selectedModel,
  $selectedManufacturerId,
  $modelsMap,
  (selectedModel, selectedManufacturerId, modelsMap) =>
    modelsMap[toKey(selectedManufacturerId, selectedModel)] ?? null
);

export const $modelsList = combine(
  $selectedManufacturerId,
  $modelsByManufacturerId,
  (selectedManufacturerId, modelsByManufacturerId) =>
    modelsByManufacturerId[selectedManufacturerId] ?? []
);

export const $yearsList = combine(
  $selectedManufacturerId,
  $selectedModelId,
  $yearsByModelsAndManufacturerIds,
  (manufacturerId, modelId, yearsByModelsAndManufacturerIds) =>
    (yearsByModelsAndManufacturerIds[toKey(manufacturerId, modelId)] ?? []).map(
      (year) => year.toString()
    )
);

const $customerId = Gate.state.map((customer) => customer["@id"]);

export const createBoatFx = attach({
  effect: createEffect(fromApi(postBoatCollectionByManager)),
  source: $customerId,
  mapParams: (formData, customerId) => ({
    body: { ...formData, client: customerId, year: parseInt(formData.year) },
  }),
});

const getManufacturersFx = attach({
  effect: fromApi(getManufacturers),
  mapParams: () => ({}),
});

const getModelsByManufacturerFx = attach({
  effect: fromApi(getModelsByManufacturer),
  mapParams: (selectedManufacturerId: number) => ({
    path: { manufacturerId: selectedManufacturerId },
  }),
});

const getYearsByModelFx = attach({
  effect: fromApi(getYearsByManufacturerModel),
  mapParams: ({
    selectedManufacturerId,
    selectedModelId,
  }: {
    selectedManufacturerId: number;
    selectedModelId: number;
  }) => ({
    path: { manufacturerId: selectedManufacturerId, modelId: selectedModelId },
  }),
});

const getBoatSizeFx = attach({
  effect: fromApi(getBoatsByManufacturerModelYear),
  mapParams: ({
    selectedManufacturerId,
    selectedModelId,
    selectedYear,
  }: {
    selectedManufacturerId: number;
    selectedModelId: number;
    selectedYear: number;
  }) => ({
    path: {
      manufacturerId: selectedManufacturerId,
      modelId: selectedModelId,
      year: selectedYear,
    },
  }),
});

export const $isFormSubmitting = createBoatFx.pending;
export const newBoatCreated = createBoatFx.done;

$newBoat.on(createBoatFx.doneData, (_, data) => data);

$manufacturersList.on(getManufacturersFx.doneData, (_, manufacturers) =>
  manufacturers.map((manufacturer) => manufacturer.name ?? "")
);

$manufacturersMap.on(getManufacturersFx.doneData, (_, manufacturers) =>
  convertToMap(manufacturers)
);

$modelsMap.on(
  getModelsByManufacturerFx.done,
  (previousMap, { params: manufacturerId, result }) => {
    const newModelsMap = result.reduce((map, item) => {
      map[toKey(manufacturerId, item.name)] = item.id;
      return map;
    }, {});

    return {
      ...previousMap,
      ...newModelsMap,
    };
  }
);

$modelsByManufacturerId.on(
  getModelsByManufacturerFx.done,
  (previousModels, { params: manufacturerId, result }) => {
    const newModels = {
      [manufacturerId]: result.map((model) => model.name),
    } as Record<ManufacturerId, ModelName[]>;
    return {
      ...previousModels,
      ...newModels,
    };
  }
);

$yearsByModelsAndManufacturerIds.on(
  getYearsByModelFx.done,
  (
    previousYears,
    { params: { selectedManufacturerId, selectedModelId }, result }
  ) => {
    const newYears = {
      [toKey(selectedManufacturerId, selectedModelId)]: result.map((year) =>
        String(year.year)
      ),
    };
    return {
      ...previousYears,
      ...newYears,
    };
  }
);

addBoatForm.$values.on(getBoatSizeFx.doneData, (boat, sizes) => ({
  ...boat,
  loa: sizes[0].loa as number,
  beam: sizes[0].beam as number,
}));

sample({
  clock: Gate.open,
  target: getManufacturersFx,
});

sample({
  source: $selectedManufacturerId,
  filter: combine(
    $selectedManufacturerId,
    $modelsMap,
    (selectedManufacturerId, modelsMap) =>
      selectedManufacturerId && !modelsMap[selectedManufacturerId]
  ),
  target: getModelsByManufacturerFx,
});

sample({
  source: {
    selectedManufacturerId: $selectedManufacturerId,
    selectedModelId: $selectedModelId,
  },
  filter: every([$selectedManufacturerId, $selectedModelId], Boolean),
  target: getYearsByModelFx,
});

sample({
  source: {
    selectedManufacturerId: $selectedManufacturerId,
    selectedModelId: $selectedModelId,
    selectedYear: $selectedYear.map((value) => parseInt(value)),
  },
  filter: every(
    [
      $selectedManufacturerId.map(Boolean),
      $selectedModelId.map(Boolean),
      $selectedYear.map(Boolean),
    ],
    true
  ),
  target: getBoatSizeFx,
});

reset({
  clock: Gate.close,
  target: [
    $manufacturersList,
    $modelsList,
    $modelsByManufacturerId,
    $yearsList,
  ],
});

function toKey(...args: (string | number | void)[]): string {
  return args.join("_").toLowerCase();
}

function convertToMap(
  list: readonly { id: number | void; name: string | void }[]
) {
  return list.reduce((map, item) => {
    map[toKey(item.name ?? "")] = item.id;
    return map;
  }, {});
}
