import styled, { css } from "styled-components";

import { Button } from "shared/ui/button";
import { mediaDesktop } from "shared/ui/styles/media";
import { DangerIcon } from "shared/ui/icons";

export function SuspiciousButton(props: {
  isSuspicious?: boolean;
  onClick(): void;
}): JSX.Element {
  return (
    <SButton
      variant={props?.isSuspicious ? "contained" : "outlined"}
      startIcon={<SuspiciousIcon isSuspicious={props?.isSuspicious} />}
      color={"danger"}
      onClick={props.onClick}
    >
      Suspicious user
    </SButton>
  );
}

const SButton = styled(Button)`
  white-space: nowrap;
  ${mediaDesktop} {
    width: 180px;
  }
`;

const SuspiciousIcon = styled(DangerIcon)<{ isSuspicious?: boolean }>`
  ${(props) =>
    props.isSuspicious &&
    css`
      path {
        stroke: white;
      }
    `}
`;
