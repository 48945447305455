import styled, { css } from "styled-components";
import { useHistory } from "react-router";
import { useGate, useStore } from "effector-react";

import { dayjs } from "shared/lib/dayjs-ext";
import { mediaDesktop, mediaPhone, useMedia } from "shared/ui/styles/media";
import { SearchInput } from "shared/ui";
import { Table } from "shared/ui/table";
import { Card } from "shared/ui/card";
import { MetaTags } from "shared/lib/metaTags";
import { AddIcon } from "shared/ui/icons";
import { Button } from "shared/ui/button";
import { NewCustomerModal } from "features/new-customer";
import dangerIcon from "shared/ui/icons/danger.svg";

import {
  $isModalAddOpened,
  buttonAddClicked,
  modalAddClosed,
} from "features/new-customer/model";

import {
  $clientsTotalCount,
  $currentPage,
  $clientsList,
  $itemsPerPage,
  pageChanged,
  pageGate,
  itemsPerPageChanged,
  $searchQuery,
  searchQueryChanged,
} from "./model";

export function CustomersListPage(): JSX.Element {
  useGate(pageGate);
  const { isDesktop } = useMedia();
  const history = useHistory();
  const clients = useStore($clientsList);
  const total = useStore($clientsTotalCount);
  const currentPage = useStore($currentPage);
  const rowsPerPage = useStore($itemsPerPage);
  const isAddModalOpened = useStore($isModalAddOpened);
  return (
    <>
      <Root as={Card}>
        <MetaTags title="Customers" />
        <Header>
          <Title>List of Customers</Title>
          <AddButton Icon={AddIcon} onClick={buttonAddClicked}>
            {isDesktop && "Add Customer"}
          </AddButton>
        </Header>
        <Search />
        {clients && (
          <Table
            columns={[
              {
                header: "ID",
                renderCell: (client) => client?.id ?? "",
              },
              {
                header: "Name",
                renderCell: (client) => (
                  <Name isSuspicious={client.isSuspicious}>
                    {client.firstName} {client.lastName}
                  </Name>
                ),
              },
              {
                header: "Registration Date",
                align: "justify",
                renderCell: (client) =>
                  client.createdAt
                    ? dayjs(client.createdAt).format("MM/DD/YYYY")
                    : "",
              },
              {
                header: "Money Spent ",
                align: "left",
                renderCell: (client) =>
                  client.spentMoney ? `$ ${client.spentMoney}` : "",
              },
              {
                header: "Notes",
                align: "left",
                renderCell: (client) => client?.comment ?? "",
              },
            ]}
            items={clients}
            onRowClick={(client) => {
              history.push(`/customers-list/${client.id}`);
            }}
            pagination={{
              total: total,
              onPageChange: pageChanged,
              onItemsPerPageChange: itemsPerPageChanged,
              currentPage: currentPage,
              itemsPerPage: rowsPerPage,
              rowsPerPageOptions: [5, 10, 20],
            }}
            moreLink={(client) => `/customers-list/${client.id}`}
            moreLinkLabel="More information"
          />
        )}
      </Root>
      <NewCustomerModal isOpened={isAddModalOpened} onClose={modalAddClosed} />
    </>
  );
}

function SearchComponent(props: { className?: string }): JSX.Element {
  const searchQuery = useStore($searchQuery);
  return (
    <SearchInput
      className={props.className}
      label="Search"
      value={searchQuery ? searchQuery : ""}
      onChange={searchQueryChanged}
    />
  );
}

const Name = styled.div<{ isSuspicious: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  ${(props) =>
    props.isSuspicious &&
    css`
      color: #de2016;
      :after {
        display: block;
        flex-shrink: 0;
        content: "";
        background: url(${dangerIcon});
        background-size: cover;
        width: 20px;
        height: 20px;
      }
    `}
`;

const Search = styled(SearchComponent)`
  max-width: 290px;
  ${mediaPhone} {
    max-width: none;
    width: 100%;
  }
`;

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddButton = styled(Button)`
  white-space: nowrap;
  ${mediaDesktop} {
    width: 180px;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;

  ${Header} {
    margin-bottom: 16px;
    ${mediaPhone} {
      margin-bottom: 24px;
    }
  }

  ${Search} {
    margin-bottom: 8px;
    ${mediaPhone} {
      margin-bottom: 16px;
    }

    ${mediaPhone} {
      ${Table} {
        margin: 0 calc(var(--pageContentPadding) * -1);
      }
    }
  }
`;
