import styled from "styled-components";
import { useGate, useStoreMap } from "effector-react";

import {
  createFormField,
  Select,
  SelectProps,
} from "shared/ui/ecosystems/forms";

import { $addonsList, Gate } from "../models/addon-selector-model";

type Props = Omit<SelectProps<string>, "options"> & {
  selected: { addon: string }[];
};

export function AddonSelectorComponent(props: Props): JSX.Element {
  useGate(Gate);
  const options = useStoreMap({
    store: $addonsList,
    fn: (types) =>
      types
        .filter(
          (type) =>
            !props.selected.map((item) => item.addon).includes(type["@id"]) ||
            type["@id"] === props.value
        )
        .map((type) => ({
          label: type.name,
          value: type["@id"],
        })),
    keys: [props.selected],
  });

  return <Select {...props} options={options} />;
}

export const AddonSelector = styled(AddonSelectorComponent)``;

export const AddonField = createFormField(AddonSelector);
