import styled from "styled-components";
import { useGate, useStore } from "effector-react";
import { Button as MaterialButton } from "@mui/material";

import { mediaPhone } from "shared/ui/styles/media";
import { SearchInput } from "shared/ui";
import { ActionDelete, ActionEdit, Table, TableActions } from "shared/ui/table";
import { Card as CardComponent, CardTitle } from "shared/ui/card";

import { PlusIcon, TrashIcon } from "shared/ui/icons";

import { NewManagerModal } from "@manager-app/features/manage-managers/add";
import {
  buttonAddClicked,
  modalAddClosed,
  $isModalAddOpened,
} from "@manager-app/features/manage-managers/add/model";
import { deleteManager } from "@manager-app/features/manage-managers/delete";
import { ConfirmModal, useConfirmModal } from "shared/ui/modals/confirm-modal";
import { MetaTags } from "shared/lib/metaTags";

import { EditManagerModal } from "@manager-app/features/manage-managers/edit";
import {
  $isModalEditOpened,
  editButtonClicked,
  modalEditClosed,
} from "@manager-app/features/manage-managers/edit/model";

import {
  $managersTotalCount,
  $currentPage,
  $managersList,
  $itemsPerPage,
  pageChanged,
  pageGate,
  itemsPerPageChanged,
  $searchQuery,
  searchQueryChanged,
} from "./model";

export function DockManagerListPage(): JSX.Element {
  useGate(pageGate);
  const managersList = useStore($managersList);
  const total = useStore($managersTotalCount);
  const currentPage = useStore($currentPage);
  const rowsPerPage = useStore($itemsPerPage);
  const isAddModalOpened = useStore($isModalAddOpened);
  const isEditModalOpened = useStore($isModalEditOpened);

  const searchQuery = useStore($searchQuery);
  function handleAddButton() {
    buttonAddClicked();
  }
  const [confirmDeleting, confirmModalProps] = useConfirmModal(
    (manager: typeof managersList[number]) => deleteManager(manager.id),
    (manager) => ({
      message: `Are you sure you want to delete ${manager.firstName} ${manager.lastName}?`,
    })
  );

  return (
    <>
      <MetaTags title="Managers" />
      <Root>
        <Card>
          <HeaderWrap>
            <Header>List of Dock Managers</Header>
            <AddButton startIcon={<PlusIcon />} onClick={handleAddButton}>
              Add Manager
            </AddButton>
          </HeaderWrap>
          <Search
            searchQuery={searchQuery}
            searchQueryChanged={searchQueryChanged}
          />
          {managersList && (
            <Table
              columns={[
                {
                  header: "ID",
                  renderCell: (manager) => {
                    return manager.id;
                  },
                },
                {
                  header: "Name",
                  renderCell: (manager) =>
                    `${manager.firstName} ${manager.lastName}`,
                },
                {
                  header: "Dock Company",
                  align: "justify",
                  renderCell: (manager) => {
                    return manager.company ? manager.company.name : null;
                  },
                },
                {
                  header: "Email",
                  align: "left",
                  renderCell: (manager) => manager.email,
                },
                {
                  header: "Phone",
                  align: "left",
                  renderCell: (manager) => manager.phone ?? "",
                },
                {
                  header: "Action",
                  align: "right",
                  renderCell: (manager) => (
                    <TableActions>
                      <ActionEdit onClick={() => editButtonClicked(manager)} />
                      <ActionDelete onClick={() => confirmDeleting(manager)} />
                    </TableActions>
                  ),
                },
              ]}
              items={managersList}
              pagination={{
                total: total,
                onPageChange: pageChanged,
                onItemsPerPageChange: itemsPerPageChanged,
                currentPage: currentPage,
                itemsPerPage: rowsPerPage,
                rowsPerPageOptions: [5, 10, 20],
              }}
            />
          )}
        </Card>
      </Root>
      <NewManagerModal isOpened={isAddModalOpened} onClose={modalAddClosed} />
      <EditManagerModal
        isOpened={isEditModalOpened}
        onClose={modalEditClosed}
      />

      <ConfirmModal
        {...confirmModalProps}
        title={"Delete manager?"}
        Icon={TrashIcon}
        confirmText="Yes, delete"
      />
    </>
  );
}

function SearchComponent(props: {
  className?: string;
  searchQuery: string | null;
  searchQueryChanged(): void;
}): JSX.Element {
  const searchQuery = props.searchQuery;
  return (
    <SearchInput
      className={props.className}
      label="Search"
      value={searchQuery ? searchQuery : ""}
      onChange={props.searchQueryChanged}
    />
  );
}

const Header = styled(CardTitle)`
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const AddButton = styled(MaterialButton)`
  white-space: nowrap;
  width: 180px;
  ${mediaPhone} {
    width: 160px;
    min-width: 160px;
  }
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Search = styled(SearchComponent)`
  width: 290px;
  ${mediaPhone} {
    width: 100%;
  }
`;

const Card = styled(CardComponent)`
  ${mediaPhone} {
    padding: 0;
  }
`;

const Root = styled.div`
  ${mediaPhone} {
    // @ts-ignore
    ${Table} {
      margin: 0 calc(var(--pageContentPadding) * -1);
    }
  }

  ${Header} {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;

    ${Search} {
      margin-bottom: 24px;

      ${mediaPhone} {
        margin-bottom: 16px;
      }
    }
  }
`;
