import styled from "styled-components";
import { Box } from "@mui/material";

import { useStore } from "effector-react";

import { mediaPhone } from "shared/ui/styles/media";
import {
  FormError,
  FormModalTemplate,
  InputField,
  PhoneField,
  SubmitButton,
} from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";
import { addUserApiErrorsByStatuses } from "shared/api";

import { $formSubmitting, $serverErrorStatus, editManagerForm } from "../model";

export function EditManagerModal(props: {
  isOpened: boolean;
  onClose(): void;
}): JSX.Element {
  const isSubmitting = useStore($formSubmitting);
  const errorStatus = useStore($serverErrorStatus);
  const error =
    (errorStatus && addUserApiErrorsByStatuses[errorStatus]) ?? "Unknown error";

  return (
    <FormModalTemplate
      form={editManagerForm}
      isOpened={props.isOpened}
      onClose={props.onClose}
      renderTitle={() => "Edit manager"}
      renderContent={() => (
        <>
          {errorStatus && <FormError>{error}</FormError>}
          <Form form={editManagerForm}>
            <FormFields>
              <InputField
                name="firstName"
                type="string"
                label="First name"
                placeholder="Enter first name *"
              />
              <InputField
                name="lastName"
                type="string"
                label="Last Name"
                placeholder="Enter last name *"
              />
              <InputField
                name="email"
                type="email"
                label="Email"
                placeholder="Enter email *"
              />
              <PhoneField name="phone" label="Phone Number" />
            </FormFields>
          </Form>
        </>
      )}
      renderActions={() => (
        <SubmitButton isSubmitting={isSubmitting}>Edit manager</SubmitButton>
      )}
    />
  );
}

export const FormFields = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;
