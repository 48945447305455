import { ConfirmModal } from "shared/ui/modals";
import { useUnit } from "effector-react";

import { $client, confirmModal } from "../model";

export function ConfirmSuspiciousModal(): JSX.Element {
  const confirm = useUnit(confirmModal);
  const client = useUnit($client);
  return (
    <>
      <ConfirmModal
        title={`${
          client?.isSuspicious ? "Unmark" : "Mark"
        } user as suspicious?`}
        message={`Are you sure you want to ${
          client?.isSuspicious ? "unmark" : "mark"
        } 
        ${client?.firstName} ${client?.lastName} as a suspicious user?`}
        {...confirm}
      />
    </>
  );
}
