import styled, { css } from "styled-components";
import { useGate, useUnit } from "effector-react";

import { Card, CardTitle } from "shared/ui/card";
import { MetaTags } from "shared/lib/metaTags";

import { Form } from "shared/lib/form";

import { mediaPhone } from "shared/ui/styles/media";
import { RichTextEditorField, SubmitButton } from "shared/ui/ecosystems/forms";

import { $dock, $isFormSubmitting, form, pageGate } from "./model";

export function TermsAndConditionsDockEditPage(): JSX.Element {
  useGate(pageGate);
  const [dock, isFormSubmitting] = useUnit([$dock, $isFormSubmitting]);

  return (
    <Root as={Card} $hide={!dock}>
      <MetaTags title="Terms & Conditions" />
      {dock && <Title>Terms and Conditions for {dock.name}</Title>}

      <FormRoot as={Form} form={form}>
        <Fields>
          <RichTextEditorField name="terms" />
        </Fields>
        <SubmitButton isSubmitting={isFormSubmitting}>Save</SubmitButton>
      </FormRoot>
    </Root>
  );
}

const Title = styled(CardTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Fields = styled.div`
  width: 100%;
`;

const FormRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  ${SubmitButton} {
    width: 322px;
    max-width: 100%;
  }
`;

const Root = styled.div<{ $hide: boolean }>`
  width: 716px;
  max-width: 100%;

  ${(props) =>
    props.$hide &&
    css`
      display: none;
    `}
`;
