import { useGate, useStore } from "effector-react";

import { Form } from "shared/lib/form";

import { FormFields } from "./dock-form/form-fields";
import { DockAddSuccess } from "./dock-form-success-modals";

import {
  addDockForm,
  fieldArray,
  formGate,
  $isAddDockFormSubmitting,
  $cities,
} from "../model";

export function NewDockForm(): JSX.Element {
  useGate(formGate);

  const isFormSubmitting = useStore($isAddDockFormSubmitting);
  const cities = useStore($cities);

  return (
    <Form form={addDockForm} fieldArray={fieldArray}>
      <FormFields
        submitText="Create Dock"
        isSubmitting={isFormSubmitting}
        cities={cities}
      />
      <DockAddSuccess />
    </Form>
  );
}
