import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";

import { apiRequestFx } from "shared/api";

import { confirmEvent } from "shared/lib/confirm-modal";
import { Client } from "shared/api/types";

export const $client = createStore<Client | null>(null);

export const setSuspicious = createEvent<Client | null>();

export const suspiciousSet = createEvent();

const setClientSuspiciousFx = attach({
  effect: createEffect(
    async (params: { isSuspicious: boolean; clientId: number }) => {
      const responce = await apiRequestFx({
        method: "PUT",
        path: `/api/clients/${params.clientId}/is-suspicious`,
        body: { isSuspicious: params.isSuspicious },
      });
      return responce;
    }
  ),
  source: $client,
  mapParams: (_, client: Client) => ({
    clientId: client.id,
    isSuspicious: !client.isSuspicious,
  }),
});

export const confirmModal = confirmEvent({
  clock: setSuspicious,
  target: setClientSuspiciousFx,
});

sample({
  clock: setSuspicious,
  target: $client,
});

sample({
  clock: setClientSuspiciousFx.doneData,
  target: suspiciousSet,
});
