import styled from "styled-components";
import { useGate, useUnit } from "effector-react";
import { useHistory } from "react-router";

import { AddReservationForm } from "@manager-app/features/manage-manager-reservation/ui/add-reservation-form";
import { NewCustomerForm } from "features/new-customer/ui/new-customer-form";
import { AddBoatForm } from "@manager-app/features/manage-boats/add";
import {
  $isCustomerSelected,
  buttonCustomerChangeClicked,
  CustomerSelectField,
  $selectedCustomer,
} from "@manager-app/entities/customers";

import { CheckBox } from "shared/ui/ecosystems/forms";
import { Client } from "shared/api/types";

import {
  $isBoatSelected,
  buttonBoatChangeClicked,
  BoatSelectField,
} from "entities/boats";

import { EditIcon } from "shared/ui/icons";
import { Dock } from "entities/docks";

import { AddonsListSelector } from "@manager-app/entities/addons";

import { ReservationsPageTemplate } from "../ui";

import {
  $isNewCustomer,
  newCustomerToggled,
  $isNewBoat,
  newBoatToggled,
  $isSelectBoatEnable,
  $isSelectAddonsEnable,
  pageGate,
  $selectedDock,
  successModal,
  successModalActions,
} from "./model";
import { SuccessModal } from "./modal";

export function ReservationsListAddPage(): JSX.Element {
  useGate(pageGate);
  const [customer, dock, isSuccessModalOpened] = useUnit([
    $selectedCustomer,
    $selectedDock,
    successModal,
  ]);

  const history = useHistory();

  return (
    <ReservationsPageTemplate backLink={"/reservations-list"}>
      <AddReservationForm
        renderCustomerSelect={() => <CustomerSelect />}
        renderBoatSelect={() => <BoatSelect customer={customer} />}
        renderAddOnSelect={() => <AddonsSelect dock={dock} />}
      />

      <SuccessModal
        isOpened={isSuccessModalOpened}
        onClose={() => {
          history.push(`/reservations-list`);
          successModalActions.close();
        }}
        message="Reservation created!"
      />
    </ReservationsPageTemplate>
  );
}

function CustomerSelect(): JSX.Element {
  const [isNewCustomer, isCustomerSelected] = useUnit([
    $isNewCustomer,
    $isCustomerSelected,
  ]);
  return (
    <SectionRoot>
      <Content>
        <Title>Select customer </Title>
        <CustomerSelectField disabled={isNewCustomer} />
        {!isCustomerSelected && (
          <CheckBox value={isNewCustomer} onChange={newCustomerToggled}>
            New customer
          </CheckBox>
        )}
        {isNewCustomer && <NewCustomerForm />}
      </Content>

      {isCustomerSelected && (
        <ChangeButton onClick={buttonCustomerChangeClicked} />
      )}
    </SectionRoot>
  );
}

function BoatSelect(props: { customer: Client }): JSX.Element {
  const [isNewBoat, isBoatSelected, isSelectBoatEnable] = useUnit([
    $isNewBoat,
    $isBoatSelected,
    $isSelectBoatEnable,
  ]);
  return (
    <SectionRoot disabled={!isSelectBoatEnable}>
      <Content>
        <Title>Select customer boat</Title>
        <BoatSelectField disabled={isNewBoat} customer={props.customer} />
        {!isBoatSelected && isSelectBoatEnable && (
          <CheckBox value={isNewBoat} onChange={newBoatToggled}>
            New boat
          </CheckBox>
        )}
        {isNewBoat && <AddBoatForm customer={props.customer} />}
      </Content>
      {isBoatSelected && <ChangeButton onClick={buttonBoatChangeClicked} />}
    </SectionRoot>
  );
}

function AddonsSelect(props: { dock: Dock }): JSX.Element {
  const [isSelectAddonsEnable] = useUnit([$isSelectAddonsEnable]);
  return (
    <SectionRoot disabled={!isSelectAddonsEnable}>
      <AddonsListSelector
        disabled={!isSelectAddonsEnable}
        dockAddons={props?.dock?.addons}
      />
    </SectionRoot>
  );
}

const ChangeButton = styled(EditIcon)`
  width: 40px;
  height: 40px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
`;

const SectionRoot = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  ${Content} {
    margin-bottom: 32px;
  }
`;
