import React from "react";
import { useUnit, useGate } from "effector-react";
import styled from "styled-components";

import { FormError, SubmitButton } from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";
import { Card } from "shared/ui/card";
import { mediaPhone } from "shared/ui/styles/media";
import { ReservationCost } from "entities/reservations/ui/reservation-cost";

import {
  $formSubmitting,
  $priceInfo,
  newReservationForm,
  $isLOADisabled,
  $isSubmitEnabled,
  loaChangeModal,
  loaChangeModalActions,
  superOverrideModal,
  superOverrideModalActions,
  superOverrideNotConfirmed,
  formGate,
} from "../model/add-reservatoin.model";
import { ReservationFormFields } from "./form-fields";
import { LOAChangeModal } from "./loa-change-modal";
import { SuperOverrideModal } from "./superoverride-modal";

export function AddReservationForm(props: {
  renderCustomerSelect(): React.ReactNode;
  renderBoatSelect(): React.ReactNode;
  renderAddOnSelect(): React.ReactNode;
}): JSX.Element {
  const [
    formSubmitting,
    priceInfo,
    isLOADisabled,
    isLoaChangeModalOpened,
    isSubmitEnabled,
    isSuperOverrideModalOpened,
  ] = useUnit([
    $formSubmitting,
    $priceInfo,
    $isLOADisabled,
    loaChangeModal,
    $isSubmitEnabled,
    superOverrideModal,
  ]);
  useGate(formGate);
  return (
    <Root>
      <Column>
        <Form form={newReservationForm}>
          <Section>
            <Title>Add reservation </Title>
            <Subtitle>
              Select or create a customer to make a reservation
            </Subtitle>
            <FormContent>
              <ReservationFormFields isLOADisabled={isLOADisabled} />
            </FormContent>
          </Section>
        </Form>
        {props.renderCustomerSelect && (
          <Section>{props.renderCustomerSelect()}</Section>
        )}
        {props.renderBoatSelect && (
          <Section>{props.renderBoatSelect()}</Section>
        )}
        {props.renderAddOnSelect && (
          <Section>{props.renderAddOnSelect()}</Section>
        )}
      </Column>
      <Column>
        <Section>
          <CheckBlock>
            <SectionTitle>Total</SectionTitle>
            <ReservationCost priceInfo={priceInfo} />

            <Form form={newReservationForm}>
              <Action>
                <SubmitButton
                  disabled={!isSubmitEnabled}
                  isSubmitting={formSubmitting}
                >
                  Create reservation
                </SubmitButton>
              </Action>
            </Form>
          </CheckBlock>
        </Section>
      </Column>
      <LOAChangeModal
        isOpened={isLoaChangeModalOpened}
        onClose={loaChangeModalActions.close}
      />
      <SuperOverrideModal
        isOpened={isSuperOverrideModalOpened}
        onConfirm={superOverrideModalActions.close}
        onCancel={superOverrideNotConfirmed}
      />
    </Root>
  );
}

const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
`;

const Action = styled.div`
  display: flex;
  justify-content: center;

  ${SubmitButton} {
    width: 338px;
    margin-top: 26px;
  }
`;

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const CheckBlock = styled.div<{ disabled?: boolean }>`
  position: sticky;

  display: flex;
  flex-direction: column;
  gap: 9px;
  ${SectionTitle} {
    margin-bottom: 5px;
  }
`;

const Section = styled(Card)`
  position: sticky;
  top: calc(var(--headerHeight) + 20px);

  display: flex;
  flex-direction: column;
  gap: 16px;
  ${mediaPhone} {
    width: calc(100vw - 32px);
  }
  ${Subtitle} {
    margin-top: -16px;
  }
`;

const FormContent = styled.div`
  ${FormError} {
    margin-bottom: 24px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Root = styled.div`
  display: grid;
  grid-template-columns: 716px 386px;
  flex-direction: column;
  gap: 24px;
`;
