import styled from "styled-components";
import { useState } from "react";
import { useHistory } from "react-router";
import { useGate, useUnit } from "effector-react";

import { Card } from "shared/ui/card";
import { mediaPhone } from "shared/ui/styles/media";
import { Button } from "shared/ui/button";
import { Form } from "shared/lib/form";
import { EditIcon } from "shared/ui/icons";
import { ReservationsPageTemplate } from "@manager-app/pages/reservations-list/ui";
import { CustomerLine } from "@manager-app/entities/customers";
import { BoatLine } from "entities/boats/ui/boat-select-field/boat-select-field";
import { Select, SubmitButton } from "shared/ui/ecosystems/forms";
import { ReservationCost } from "entities/reservations/ui/reservation-cost";
import {
  FormTemplate,
  BillingFormFields,
  paymentFormSubmitted,
  $isPaymentSent,
} from "@manager-app/features/pay-for-reservation";
import { PaymentForm, PaymentFormFields } from "shared/ui/payment-form";
import { dayjs } from "shared/lib/dayjs-ext/dayjs-ext";

import {
  $reservation,
  reservationPayPageGate,
  $paymentType,
  paymentTypeChanged,
  buttonSaveClicked,
  successPayInfoModal,
  successPayInfoModalActions,
  unSuccessPayInfoModal,
  unSuccessPayInfoModalActions,
  successPaymentModal,
  successPaymentModalActions,
  unSuccessPaymentModal,
  unSuccessPaymentModalActions,
  billingForm,
  $billingSubmitted,
  $billingSaved,
  buttonBillingChangeClicked,
} from "../model";

import { ReservationAddons } from "./reservation-addons";
import { SuccessModal, UnSuccessModal } from "./modal";

export function ReservationPayPage(): JSX.Element | null {
  useGate(reservationPayPageGate);
  const [
    reservation,
    paymentType,
    isSuccessPayInfoModalOpened,
    isUnSuccessPayInfoModalOpened,
    isSuccessPaymentModalOpened,
    isUnSuccessPaymentModalOpened,
    billingSubmitted,
    billingSaved,
    isPaymentSent,
  ] = useUnit([
    $reservation,
    $paymentType,
    successPayInfoModal,
    unSuccessPayInfoModal,
    successPaymentModal,
    unSuccessPaymentModal,
    $billingSubmitted,
    $billingSaved,
    $isPaymentSent,
  ]);

  const [paymentFormComplete, setPaymentFormComplete] = useState(false);

  if (!reservation) return <></>;

  const history = useHistory();
  return (
    <ReservationsPageTemplate backLink={"/reservations-list"}>
      <Root>
        <Column>
          <Section>
            <PageTitle>
              Reservation <ReservationId>{`#${reservation.id}`}</ReservationId>
              Payment
            </PageTitle>
            <Subtitle>
              You have to pay for this reservation before
              <PaymentTime>{paymentTime(reservation.createdAt)}</PaymentTime>
            </Subtitle>
            <InfoBlock>
              <CustomerLine customer={reservation.client} />
            </InfoBlock>
            <InfoBlock>
              <BoatLine
                boat={{
                  ...reservation.boat,
                  loa: reservation.loa,
                  beam: reservation.beam,
                }}
              />
            </InfoBlock>
            {(reservation.isCharter || reservation.price.addons.length > 0) && (
              <ReservationAddons reservation={reservation} />
            )}
          </Section>
          <Section>
            <Header>
              <div>
                <Title>Payment</Title>
                {billingSaved && <PaymentType>Online payment</PaymentType>}
              </div>
              {billingSaved && (
                <ChangeButton onClick={buttonBillingChangeClicked} />
              )}
            </Header>
            {!billingSaved && (
              <>
                <PaymentTypeSelect
                  as={Select}
                  label="Select payment type"
                  value={paymentType}
                  onChange={paymentTypeChanged}
                  options={[
                    { label: "Pay at Dock", value: "atDock" },
                    { label: "Online pay-for-reservation", value: "onLine" },
                  ]}
                />
                {paymentType === "atDock" && (
                  <>
                    <Description>
                      The order will be paid at the dock. Click "Save" button
                      below to approve.
                    </Description>
                    <Action>
                      <Button onClick={buttonSaveClicked}>Save</Button>
                    </Action>
                  </>
                )}
                {paymentType === "onLine" && (
                  <>
                    <BillingTitle>Billing address</BillingTitle>
                    <Form form={billingForm}>
                      <FormTemplate
                        renderFields={() => <BillingFormFields />}
                        renderActions={() => (
                          <Action>
                            <SubmitButton isSubmitting={billingSubmitted}>
                              Save & next step
                            </SubmitButton>
                          </Action>
                        )}
                      />
                    </Form>
                  </>
                )}
              </>
            )}
          </Section>
          <Section disabled={!billingSaved}>
            <Title>Client payment methods</Title>
            {billingSaved && (
              <PaymentForm onSubmit={paymentFormSubmitted}>
                <FormTemplate
                  renderFields={() => (
                    <Fields>
                      <PaymentFormFields
                        onComplete={() => setPaymentFormComplete(true)}
                        onInComplete={() => setPaymentFormComplete(false)}
                      />
                    </Fields>
                  )}
                  renderActions={() => (
                    <Action>
                      <SubmitButton
                        isSubmitting={isPaymentSent}
                        disabled={!paymentFormComplete}
                      >
                        Add method & pay
                      </SubmitButton>
                    </Action>
                  )}
                />
              </PaymentForm>
            )}
          </Section>
        </Column>
        <Column>
          <Section>
            <Title>Total</Title>
            <ReservationCost priceInfo={reservation.price} />
          </Section>
        </Column>
      </Root>
      <SuccessModal
        isOpened={isSuccessPayInfoModalOpened}
        onClose={() => {
          history.push(`/reservations-list`);
          successPayInfoModalActions.close();
        }}
        message="Payment information saved!"
      />
      <UnSuccessModal
        isOpened={isUnSuccessPayInfoModalOpened}
        onClose={unSuccessPayInfoModalActions.close}
      />
      <SuccessModal
        isOpened={isSuccessPaymentModalOpened}
        onClose={() => {
          history.push(`/reservations-list`);
          successPaymentModalActions.close();
        }}
        message="Booking is successful!"
      />
      <UnSuccessModal
        isOpened={isUnSuccessPaymentModalOpened}
        onClose={unSuccessPaymentModalActions.close}
      />
    </ReservationsPageTemplate>
  );
}

function paymentTime(timeCreated: string | void): string {
  const secondForPayment = 900;
  const createdAt = dayjs.tz(timeCreated ?? "");
  return dayjs(createdAt).add(secondForPayment, "second").format("hh:mm A");
}

const ReservationId = styled.div`
  color: #5e87ee;
`;

const PaymentTime = styled.div`
  color: #5e87ee;
  font-weight: 600;
`;

const PageTitle = styled.div`
  display: flex;
  gap: 4px;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
`;

const Subtitle = styled.div`
  display: flex;
  gap: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
`;

const BillingTitle = styled(Title)``;

const Action = styled.div`
  display: flex;
  justify-content: center;

  ${Button} {
    width: 332px;
    margin-top: 26px;
  }
`;

const PaymentTypeSelect = styled.div`
  width: 322px;
`;

const PaymentType = styled.div`
  color: #5e88ef;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin-top: 16px;
`;

const Fields = styled.div`
  width: 380px;
`;

const ChangeButton = styled(EditIcon)`
  width: 40px;
  height: 40px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  :before {
    content: "* ";
    font-size: 14px;
    font-weight: 600;
    color: #de2016;
  }
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: #f7f9fc;
  border: 1px solid #e7ecf7;
  border-radius: 12px;
  ${BoatLine} {
    height: 68px;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Section = styled(Card)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  ${mediaPhone} {
    width: calc(100vw - 32px);
  }
  ${Subtitle} {
    margin-top: -16px;
    margin-bottom: 8px;
  }
  ${BillingTitle} {
    margin-top: 16px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Root = styled.div`
  display: grid;
  grid-template-columns: 716px 386px;
  flex-direction: column;
  gap: 24px;
`;
