import styled from "styled-components";
import { Box } from "@mui/material";

import { useGate, useStore } from "effector-react";

import {
  FormError,
  FormModalTemplate,
  SubmitButton,
  TextAreaField,
} from "shared/ui/ecosystems/forms";

import {
  $formSubmitting,
  $serverErrorStatus,
  editCommentForm,
  FormEditGate,
} from "./model";

export function EditCommentModal(props: {
  isOpened: boolean;
  onClose(): void;
}): JSX.Element {
  const isSubmitting = useStore($formSubmitting);

  const errorStatus = useStore($serverErrorStatus);

  return (
    <FormModalTemplate
      form={editCommentForm}
      isOpened={props.isOpened}
      onClose={props.onClose}
      renderTitle={() => "Edit comment about client"}
      renderContent={() => {
        useGate(FormEditGate);
        return (
          <>
            {errorStatus && <FormError>"Unknown error"</FormError>}

            <FormFields>
              <TextAreaField
                name="comment"
                placeholder="Edit comment"
                minRows={3}
                maxRows={3}
              />
            </FormFields>
          </>
        );
      }}
      renderActions={() => (
        <SubmitButton isSubmitting={isSubmitting}>Save comment</SubmitButton>
      )}
    />
  );
}

export const FormFields = styled(Box)`
  display: flex;
  flex-direction: column;
`;
