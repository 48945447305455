import { useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useStore } from "effector-react";

import { AppContext } from "shared/init";
import { postManagerLogout } from "shared/api";
import { syncHistoryWithState } from "shared/lib/effector-router-params";
import {
  AuthorizedAdminsPageTemplate,
  NavigationMenu,
  NavigationMenuIcon as NavIcon,
  NavigationMenuItem,
} from "shared/ui/templates/authorized-page-template";
import { ListIcon, UsersIcon, ManagersIcon, DocksIcon } from "shared/ui/icons";
import { setHistory } from "shared/config/histroy";

import { $viewer, logout } from "../entities/viewer";
import { noAuthRoutes, authRoutes } from "./routes";
import { init } from "./init";

const history = createBrowserHistory();
setHistory(history);
syncHistoryWithState(history, authRoutes);

init();

syncHistoryWithState(history, authRoutes);

export function App(): JSX.Element {
  const manager = useStore($viewer);

  return (
    <AppContext>
      <Router history={history}>
        {manager ? <Authorized /> : <NoAuthorized />}
      </Router>
    </AppContext>
  );
}

function NoAuthorized() {
  useEffect(() => {
    return syncHistoryWithState(history, noAuthRoutes);
  }, []);

  return renderRoutes(noAuthRoutes);
}

function Authorized() {
  const manager = useStore($viewer);

  useEffect(() => {
    return syncHistoryWithState(history, authRoutes);
  }, []);

  if (!manager) {
    return null;
  }

  return (
    <AuthorizedAdminsPageTemplate
      user={manager}
      userLabel="Manager"
      onSignOutClick={async () => {
        await postManagerLogout({});
        logout();
      }}
      renderContent={() => renderRoutes(authRoutes)}
      renderNavigation={() => (
        <NavigationMenu>
          <NavigationMenuItem to="/dock-manager-list">
            <NavIcon as={ManagersIcon} />
            Dock Managers
          </NavigationMenuItem>
          <NavigationMenuItem to="/docks-list">
            <NavIcon as={DocksIcon} />
            Docks
          </NavigationMenuItem>
          <NavigationMenuItem to="/customers-list">
            <NavIcon as={UsersIcon} /> Customers
          </NavigationMenuItem>
          <NavigationMenuItem to="/reservations-list">
            <NavIcon as={ListIcon} />
            Reservations
          </NavigationMenuItem>
          <NavigationMenuItem to="/reports">
            <NavIcon as={ListIcon} />
            Reports
          </NavigationMenuItem>
        </NavigationMenu>
      )}
    />
  );
}
