import styled from "styled-components";

import { CheckBox, TimeField } from "shared/ui/ecosystems/forms";
import { mediaPhone, useMedia } from "shared/ui/styles/media";
import { NestedFields, useField } from "shared/lib/form";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function DayOperationalHours(): JSX.Element {
  const { isDesktop } = useMedia();

  const { input } = useField({ name: "days" });
  const value = input.value ?? {};

  return (
    <NestedFields name="days">
      <DayOperationalHoursRoot>
        <List>
          {days.map((day) => (
            <Day key={day}>
              <div>
                <CheckBox
                  value={Boolean(value[day])}
                  onChange={(data) =>
                    input.onChange({ ...value, [day]: data ? {} : null })
                  }
                >
                  {isDesktop ? day : day.substr(0, 2)}
                </CheckBox>
              </div>
              <Hours>
                <TimeField name={`${day}.from`} label="From" /> <Dash>—</Dash>
                <TimeField name={`${day}.to`} label="To" />
              </Hours>
            </Day>
          ))}
        </List>
      </DayOperationalHoursRoot>
    </NestedFields>
  );
}

const Hours = styled.div`
  display: grid;
  grid-template-columns: 1fr 24px 1fr;
  grid-gap: 16px;
`;

const Dash = styled.div`
  margin-top: 35px;
`;

const Day = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  ${mediaPhone} {
    grid-template-columns: 75px 1fr;
  }

  ${Hours} {
    margin-top: -16px;
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
`;

const DayOperationalHoursRoot = styled.div`
  ${List} {
    margin-top: 16px;
  }
`;
