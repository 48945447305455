import React from "react";
import styled from "styled-components";

import { mediaPhone } from "shared/ui/styles/media";
import {
  CheckBoxField,
  DateField,
  DistanceField,
  FloatInputField,
  FtInputField,
  InputField,
  PhoneField,
  PriceField,
  RichTextEditorField,
  SelectField,
  StateField,
  SubmitButton,
  TaxField,
  TextAreaField,
  TypePriceSelectField,
} from "shared/ui/ecosystems/forms";
import { ListField, useField } from "shared/lib/form";
import { RestaurantTypeField } from "@manager-app/entities/restaurants";

import { AddonField } from "@manager-app/entities/addons";

import { City } from "shared/api/types";

import { DayOperationalHours } from "./operatoins-hours-field";
import { ListTemplate } from "./list-template";
import { ImageField } from "./image-field/image-field";

export function FormFields(props: {
  submitText: string;
  isSubmitting: boolean;
  cities: City[];
}): JSX.Element {
  const {
    input: { value: addons },
  } = useField({ name: "addons" });

  return (
    <DockFormRoot>
      <DockFormField>
        <FormBlock title="Image *">
          <ImageField />
        </FormBlock>

        <FormBlock title="Main info">
          <FieldsGroup>
            <InputField name="name" label="Enter dock name *" />
            <PhoneField name="phone" label="Enter phone number *" />
            <InputField name="email" label="Enter email * " />
            <InputField name="address" label="Enter address *" />
            <SelectField
              name="cityObject"
              label="Enter city *"
              options={props.cities.map((city) => ({
                label: city.name,
                value: city["@id"],
              }))}
            />
            <StateField name="state" label="Select state *" />
            <InputField name="zipCode" label="Enter zip code *" />
            <FtInputField name="length" label="Enter length *" />
          </FieldsGroup>
        </FormBlock>

        <FormBlock title="Dock description">
          <DockDescription>
            <RichTextEditorField name="description" className="editor-field" />
          </DockDescription>
        </FormBlock>

        <FormBlock title="Operational hours *">
          <DayOperationalHours />
        </FormBlock>

        <FormBlock title="Out of season dates">
          <FieldsGroup>
            <DateField name="outOfSeasonTimeFrom" label="From" />
            <DateField name="outOfSeasonTimeTo" label="To" />
          </FieldsGroup>
        </FormBlock>

        <FormBlock title="Price settings">
          <FieldsGroup as={DefaultPrice}>
            <PriceField name="defaultPrice" label="Default price *" />
            <TaxField name="taxRate" label="Tax Rate *" maxLength={5} />
          </FieldsGroup>
          <ListField name="prices">
            {({ map, remove, push }) => (
              <ListTemplate
                map={map}
                onDelete={remove}
                onAdd={() => push({})}
                addLabel="Add another variant"
                getItemTitle={(_, index) => `Price ${index + 1} *`}
                renderItem={() => (
                  <PriceVariant>
                    <FtInputField name="lengthFrom" label="From" min={0} />
                    <FtInputField name="lengthTo" label="To" min={0} />
                    <PriceField name="price" label="Price" min={0} />
                  </PriceVariant>
                )}
              />
            )}
          </ListField>
        </FormBlock>
        <FormBlock title="Restaurants">
          <ListField name="restaurants">
            {({ map, push, remove }) => (
              <ListTemplate
                map={map}
                onDelete={remove}
                onAdd={() => push({})}
                addLabel="Add a restaurant"
                getItemTitle={(_, index) => `Restaurant ${index + 1}`}
                renderItem={() => (
                  <FieldsGroup>
                    <RestaurantTypeField name="type" label="Select type" />
                    <InputField name="name" label="Restaurant name" />
                    <InputField name="url" label="Link to the restaurant" />
                    <DistanceField name="distance" label="Distance" />
                  </FieldsGroup>
                )}
              />
            )}
          </ListField>
        </FormBlock>
        <FormBlock title="Add-ons">
          <ListField name="addons">
            {({ map, push, remove }) => (
              <ListTemplate
                map={map}
                onDelete={remove}
                onAdd={() => push({})}
                addLabel="Add add-on"
                getItemTitle={(_, index) => `Add-on ${index + 1}`}
                renderItem={() => <AddonFields selected={addons} />}
              />
            )}
          </ListField>
        </FormBlock>
      </DockFormField>

      <CreateDockButton isSubmitting={props.isSubmitting}>
        {props.submitText}
      </CreateDockButton>
    </DockFormRoot>
  );
}

function AddonFields(props: { selected: string[] }): JSX.Element {
  const { input } = useField({ name: "taxable" });
  const isTaxable = input.value;
  return (
    <FieldsGroup>
      <AddonField
        name="addon"
        label="Select add-on"
        selected={props.selected as any}
      />
      <AddOnDescription>
        <TextAreaField
          name="description"
          label="Add-on description"
          minRows={3}
          maxRows={3}
        />
      </AddOnDescription>
      <FloatInputField name="price" label="Price *" />
      <TypePriceSelectField name="priceType" label="Price type" />
      <TaxableField name="taxable">This add-on is subject to tax</TaxableField>
      {isTaxable && (
        <>
          <FloatInputField name="tax" label="Tax" />
          <TypePriceSelectField name="taxType" label="Tax type" />
        </>
      )}
    </FieldsGroup>
  );
}

const DockDescription = styled.div`
  .editor-field {
    width: 100%;
  }

  .editor {
    min-height: 200px;
  }
`;

const AddOnDescription = styled.div`
  display: flex;
  > .MuiFormControl-root {
    width: 100%;
  }
`;

const TaxableField = styled(CheckBoxField)``;

const FieldsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }

  ${AddOnDescription} {
    grid-column: 1 / 3;
    ${mediaPhone} {
      grid-column: 1;
    }
  }

  ${TaxableField} {
    grid-column: 1 / 3;
    ${mediaPhone} {
      grid-column: 1;
    }
  }
`;

function FormBlock(props: {
  title: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <FormBlockRoot>
      <FormBlockTitle>{props.title}</FormBlockTitle>
      <FormBlockContent>{props.children}</FormBlockContent>
    </FormBlockRoot>
  );
}

const FormBlockTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #0a1128;
`;

const FormBlockContent = styled.div``;

const FormBlockRoot = styled.div`
  ${FormBlockTitle} {
    margin-bottom: 12px;
  }
`;

const DefaultPrice = styled.div``;

const PriceVariant = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const DockFormField = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
`;

const CreateDockButton = styled(SubmitButton)``;

const DockFormRoot = styled.div`
  display: flex;
  flex-direction: column;

  ${DockFormField} {
    margin-bottom: 32px;
  }

  ${DefaultPrice} {
    margin-bottom: 24px;
  }

  ${CreateDockButton} {
    width: 322px;
    align-self: center;
  }
`;
