import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { StripeCardNumberElement } from "@stripe/stripe-js";

import { stripePromise } from "shared/config/stripe";
import { apiRequestFx } from "shared/api";
import { Reservation } from "shared/api/types";

export const paymentFormSubmitted = createEvent<StripeCardNumberElement>();

type GetStripeKeyForPaymentParams = {
  reservationId: string;
  savePaymentMethod: boolean;
};

export const $reservation = createStore<Reservation | null>(null);
const $reservationId = $reservation.map((reservation) => reservation?.id);

export const getStripeKeyForPayment = createEffect(
  async (params: GetStripeKeyForPaymentParams) => {
    const { clientSecret } = await apiRequestFx({
      method: "POST",
      path: `/api/manager/reservations/${params.reservationId}/pay`,
      body: {
        savePaymentMethod: params.savePaymentMethod,
      },
    });
    return clientSecret;
  }
);

export const getStripeKeyFx = attach({
  effect: getStripeKeyForPayment,
  source: $reservationId,
  mapParams(_, reservationId: string) {
    return {
      reservationId,
      savePaymentMethod: true,
    };
  },
});

const payFx = createEffect(async (card: StripeCardNumberElement) => {
  const stripe = await stripePromise;
  const clientSecret = await getStripeKeyFx({});
  const result = await stripe?.confirmCardPayment?.(clientSecret as string, {
    payment_method: {
      card,
    },
  });
  if (result?.error) {
    throw result.error;
  }
});

sample({
  clock: paymentFormSubmitted,
  target: payFx,
});

export const paymentDone = payFx.done;
export const paymentFail = payFx.fail;
export const $isPaymentSent = payFx.pending;
