import { attach, createEvent, createStore, sample } from "effector";
import { getListFromApi, getBoatCollection } from "shared/api";
import { Boat } from "shared/api/types";
import { createGate } from "effector-react";
import { reset } from "patronum";

export const boatsListReset = createEvent();
export const boatLineClicked = createEvent<Boat>();
export const buttonBoatChangeClicked = createEvent();
export const selectedBoatChanged = createEvent();
export const boatReset = createEvent();

export const $selectedBoat = createStore<Boat | null>(null);
export const $isBoatSelected = $selectedBoat.map(Boolean);

export const $boatsList = createStore<Boat[]>([]);

export const Gate = createGate();

const getBoatsFx = attach({
  // @ts-ignore
  effect: getListFromApi(getBoatCollection),
  mapParams: (clientId) => ({
    query: { pagination: false, client: clientId },
  }),
});

sample({
  source: Gate.state,
  filter: (client) => !!client.id,
  fn: (client) => client.id,
  target: getBoatsFx,
});

$boatsList
  .on(getBoatsFx.doneData, (_, result) => result.items)
  .reset(boatsListReset);

sample({
  clock: boatLineClicked,
  target: selectedBoatChanged,
});

sample({
  clock: [buttonBoatChangeClicked, Gate.state],
  target: boatReset,
});

sample({
  clock: Gate.state,
  target: boatsListReset,
});

$selectedBoat.on(selectedBoatChanged, (_, value) => value).reset(boatReset);

reset({ clock: Gate.close, target: [$selectedBoat, $boatsList] });
