import { createForm } from "effector-react-form";
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { object, string } from "yup";

import { createValidator } from "shared/lib/form";
import { createApiErrorStatusStore, fromApi, putManagerItem } from "shared/api";
import { Manager } from "shared/api/types";
import { phoneValidationError } from "shared/config/error-text";

export const editManagerForm = createForm({
  name: "Edit Manager Form",
  validate: createValidator(
    object({
      email: string().email(" ").required(" "),
      firstName: string().required(" "),
      lastName: string().required(" "),
      phone: string().phone("US", phoneValidationError),
    })
  ),
  onSubmit: ({ values }) => editManagerFormFx(values),
});

export const $manager = createStore(null);

export const $formSubmitting = putManagerItem.pending;

const editManagerFormFx = attach({
  effect: createEffect(fromApi(putManagerItem)),
  source: $manager,
  // @ts-ignore
  mapParams: (formData: Manager, manager: Manager) => ({
    path: { id: manager.id },
    body: formData,
  }),
});

export const $serverErrorStatus = createApiErrorStatusStore(putManagerItem);

export const managerEdited = editManagerFormFx.done;

export const editButtonClicked = createEvent();
export const modalEditClosed = createEvent();

export const $isModalEditOpened = createStore(false);
$isModalEditOpened.on(managerEdited, () => false);

$isModalEditOpened.on(editButtonClicked, () => true);
$isModalEditOpened.reset(modalEditClosed);

$manager.on(editButtonClicked, (_, manager) => manager);

sample({
  clock: editButtonClicked,
  source: $manager,
  // @ts-ignore
  fn: (data: Manager) =>
    data && {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
    },
  target: editManagerForm.$values,
});
