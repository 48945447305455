import { createEffect } from "effector";
import { createForm } from "effector-react-form";
import { object, string } from "yup";

import { createValidator } from "shared/lib/form";
import { createApiErrorStatusStore, postManagerLogin } from "shared/api";
import { getManagerDataFx } from "@manager-app/entities/viewer";

const signInFormSchema = object({
  email: string().email(" ").required(" "),
  password: string().required(" "),
});

const managerSignInFx = createEffect(
  async (params: { email: string; password: string }) => {
    await postManagerLogin({ body: params });
    await getManagerDataFx({});
  }
);

export const signInForm = createForm({
  name: "Sign In Form",
  initialValues: {
    email: "",
    password: "",
  },
  validate: createValidator(signInFormSchema),
  onSubmit: ({ values }) => managerSignInFx(values),
});

export const $serverErrorStatus = createApiErrorStatusStore(managerSignInFx);
export const $formSubmitting = managerSignInFx.pending;
