import { array, boolean, InferType, number, object, string } from "yup";

import { keyValueObject } from "shared/lib/form";
import { dayjs } from "shared/lib/dayjs-ext/dayjs-ext";
import {
  phoneValidationError,
  requiredFieldValidationError,
} from "shared/config/error-text";

const length = number()
  .transform((value) => (value === "" ? null : value))
  .nullable()
  .typeError(" ")
  .required(requiredFieldValidationError)
  .min(0, " ");

const price = number()
  .transform((value) => parseFloat(value))
  .nullable()
  .typeError(requiredFieldValidationError)
  .required(" ");

export const dockFormSchema = object({
  image: string().required("Please, select another picture."),
  name: string().required(requiredFieldValidationError),
  description: string(),
  address: string().required(requiredFieldValidationError),
  city: string().required(requiredFieldValidationError),
  state: string().required(requiredFieldValidationError),
  phone: string().phone("US", phoneValidationError),
  email: string().email(" ").required(requiredFieldValidationError),
  length: number().required(requiredFieldValidationError).nullable(),
  zipCode: string().required(requiredFieldValidationError),
  days: keyValueObject({
    from: string()
      .required(requiredFieldValidationError)
      .nullable()
      .test(
        "Time from check",
        "Closing time must be later than opening time.",
        (value, ctx) => dayjs(value).isBefore(dayjs(ctx.parent.to), "hour")
      ),
    to: string()
      .required(requiredFieldValidationError)
      .nullable()
      .test("Time to check", " ", (value, ctx) =>
        dayjs(value).isAfter(dayjs(ctx.parent.from), "hour")
      ),
  }),
  outOfSeasonTimeFrom: string(),
  outOfSeasonTimeTo: string(),
  defaultPrice: price,
  taxRate: price,
  prices: array().of(
    object({
      lengthFrom: length.test("Length from check", " ", (value, ctx) =>
        value ? value < ctx.parent.lengthTo : true
      ),

      lengthTo: length.test("Length to check", " ", (value, ctx) =>
        value ? value > ctx.parent.lengthFrom : true
      ),
      price: price.min(0, " "),
    })
  ),
  restaurants: array().of(
    object({
      type: string().nullable(),
      name: string().required(requiredFieldValidationError),
      url: string().nullable(),
      distance: number()
        .transform((value) => (value === "" ? null : value))
        .nullable()
        .typeError(" ")
        .min(0, " "),
    })
  ),
  addons: array().of(
    object({
      addon: string().required(requiredFieldValidationError),
      price: price.min(0, " ").required(requiredFieldValidationError),
      priceType: string().required(requiredFieldValidationError),
      taxable: boolean().optional(),
      tax: number().when("taxable", {
        is: true,
        then: price.min(0, " ").required(requiredFieldValidationError),
        otherwise: string().optional(),
      }),
      taxType: string().nullable(),
    })
  ),
});

export type DockFormData = InferType<typeof dockFormSchema>;
