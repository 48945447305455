import styled from "styled-components";

import { Reservation } from "shared/api/types";

export function ReservationAddons(props: {
  reservation: Reservation;
}): JSX.Element {
  return (
    <Root>
      {props.reservation.isCharter ? <Charter>Charter</Charter> : null}
    </Root>
  );
}

const Charter = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #5e88ef;
`;

// const Delimiter = styled.div`
//   width: 6px;
//   height: 6px;
//   background: #b0e9e9;
//   border-radius: 50%;
// `;

const Root = styled.div`
  display: flex;
  height: 40px; //todo
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: #f7f9fc;
  border: 1px solid #e7ecf7;
  border-radius: 12px;
`;
