import { useUnit } from "effector-react";
import styled from "styled-components";

import {
  FormError,
  FormModalTemplate,
  SubmitButton,
  SelectField,
  DateField,
} from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";
import { addReservationApiErrorsByStatuses } from "shared/api";

import {
  createDurationSelector,
  createDateSelector,
  createTimeSelector,
} from "entities/reservations";

import {
  $formSubmitting,
  $price,
  $selectedDock,
  $serverErrorStatus,
  newReservationForm,
  $bookingFee,
  $charterFee,
  dateTimeModel,
  $reservationAddModalOpened,
  reservationAddModalActions,
} from "../model/add-override.model";

import { OverrideFormFields } from "./form-fields";

export function AddOverrideModal(): JSX.Element {
  const [
    reservationAddModalOpened,
    isSubmitting,
    errorStatus,
    selectedDock,
    price,
    bookingFee,
    charterFee,
  ] = useUnit([
    $reservationAddModalOpened,
    $formSubmitting,
    $serverErrorStatus,
    $selectedDock,
    $price,
    $bookingFee,
    $charterFee,
  ]);

  const error =
    (errorStatus && addReservationApiErrorsByStatuses[errorStatus]) ??
    "Unknown error";

  const taxRate = selectedDock && parseFloat(selectedDock.taxRate);

  return (
    <FormModalTemplate
      form={newReservationForm}
      isOpened={reservationAddModalOpened}
      onClose={reservationAddModalActions.close}
      renderTitle={() => "Dock Override"}
      renderSubtitle={() => (
        <>Use this form to block off dock space for a special event</>
      )}
      renderContent={() => (
        <FormContent>
          {errorStatus && <FormError>{error}</FormError>}
          <Form form={newReservationForm}>
            <OverrideFormFields
              renderDateTimeSelector={() => (
                <>
                  <DurationSelector
                    renderElement={(props) => (
                      <SelectField
                        label="Duration"
                        name="duration"
                        {...props}
                      />
                    )}
                  />

                  <DateSelector
                    renderElement={(props) => (
                      <DateField name="date" label="Date" {...props} />
                    )}
                  />
                  <TimeSelector
                    renderElement={(props) => (
                      <SelectField label="Time" name="time" {...props} />
                    )}
                  />
                </>
              )}
              selectedDock={selectedDock}
              price={price}
              taxRate={taxRate}
              bookingFee={bookingFee}
              charterFee={charterFee}
            />
          </Form>
        </FormContent>
      )}
      renderActions={() => (
        <SubmitButton isSubmitting={isSubmitting}>Create Override</SubmitButton>
      )}
    />
  );
}

const DurationSelector = createDurationSelector(dateTimeModel);
const DateSelector = createDateSelector(dateTimeModel);
const TimeSelector = createTimeSelector(dateTimeModel);

const FormContent = styled.div`
  ${FormError} {
    margin-bottom: 24px;
  }
`;
