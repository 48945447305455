import {
  EditDockForm,
  DockFormTemplate,
} from "@manager-app/features/manage-docks";
import { MetaTags } from "shared/lib/metaTags";

export function DocsListByIdEditPage(): JSX.Element {
  return (
    <DockFormTemplate title="Edit dock">
      <MetaTags title="Edit dock" />
      <EditDockForm />
    </DockFormTemplate>
  );
}
