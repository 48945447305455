import styled from "styled-components";

import { mediaDesktop, mediaPhone, useMedia } from "shared/ui/styles/media";
import { Card } from "shared/ui/card";
import { Button, LinkButton } from "shared/ui/button";
import { AddIcon } from "shared/ui/icons";
import { MetaTags } from "shared/lib/metaTags";
import {
  creatingReservationStarted,
  AddOverrideModal,
  EditManagerReservationModal,
} from "@manager-app/features/manage-overide";
import { createReservationsTable } from "features/reservation-searching";
import { EditClientReservationModal } from "features/edit-client-reservation";

import { getReservationsListFx } from "./model";

const ReservationsTable = createReservationsTable({
  getReservationsFx: getReservationsListFx,
  filtering: true,
  showClientDetails: true,
});

export function ReservationsListPage(): JSX.Element {
  const { isDesktop } = useMedia();

  return (
    <>
      <MetaTags title="Reservations" />
      <Root as={Card}>
        <Header>
          <Title>List of Reservations</Title>

          <Actions>
            <LinkButton to={"/reservations-list/add"} Icon={AddIcon}>
              {isDesktop && "Add Reservation"}
            </LinkButton>

            <AddButton Icon={AddIcon} onClick={creatingReservationStarted}>
              {isDesktop && "Add Override"}
            </AddButton>
          </Actions>
        </Header>
        <ReservationsTable />
      </Root>

      <AddOverrideModal />
      <EditClientReservationModal />
      <EditManagerReservationModal />
    </>
  );
}

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddButton = styled(Button)`
  white-space: nowrap;
  ${mediaDesktop} {
    width: 180px;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Root = styled.div`
  ${Header} {
    margin-bottom: 16px;
  }
  ${mediaPhone} {
    ${Header} {
      margin-bottom: 24px;
    }
  }
`;
