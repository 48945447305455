import { createGate } from "effector-react";
import { attach, createStore, sample } from "effector";

import { getListFromApi, getRestaurantTypeCollection } from "shared/api";
import { spreadListDataData } from "shared/lib/effector-paginatoin";

interface RestaurantType {
  "@id": string;
  name: string;
}

export const Gate = createGate();
export const $restaurantsTypesList = createStore<RestaurantType[]>([]);

const getRestaurantsTypesFx = attach({
  // @ts-ignore
  effect: getListFromApi(getRestaurantTypeCollection),
  mapParams: () => ({}),
});

spreadListDataData({
  $items: $restaurantsTypesList,
  effect: getRestaurantsTypesFx,
});

sample({
  clock: Gate.open,
  target: getRestaurantsTypesFx,
});
