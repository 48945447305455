import { createGate } from "effector-react";

import { createFieldArray, createForm } from "effector-react-form";
import { attach, createEffect, createStore, sample } from "effector";

import {
  fromApi,
  putDockItem,
  getDockItem,
  getListFromApi,
  getCityCollection,
} from "shared/api";
import { createValidator } from "shared/lib/form";
import { createRouteParamStore } from "shared/lib/effector-router-params";

import { City } from "shared/api/types";

import { addImage, imageUploaded } from "../upload-dock-image.model";
import { dockFormSchema } from "./form";

import {
  DockApiInput,
  DockForm,
  formatFormDataToApi,
  parseFormDataFromApi,
} from "../lib/form-parse-format";

export const editFormGate = createGate();

const $dockId = createRouteParamStore({
  name: "dockId",
  gate: editFormGate,
});

export const $editedDock = createStore<DockApiInput | null>(null);
export const $cities = createStore<City[]>([]);

export const editDockForm = createForm<DockForm>({
  validate: createValidator(dockFormSchema),
  // @ts-ignore
  onSubmit: ({ values }) => editDockFx(formatFormDataToApi(values)),
});
export const fieldArray = createFieldArray({
  form: editDockForm,
});

const getCitiesFx = attach({
  // @ts-ignore
  effect: getListFromApi(getCityCollection),
});

$cities.on(getCitiesFx.doneData, (_, { items }) => items);

const getDockFx = attach({
  effect: createEffect(fromApi(getDockItem)),
  source: $dockId,
  mapParams: (_, dockId) => ({ path: { id: dockId as string } }),
});

const editDockFx = attach({
  effect: createEffect(fromApi(putDockItem)),
  source: $dockId,
  mapParams: (body: DockApiInput, dockId) => ({
    path: { id: dockId as string },
    body,
  }),
});

editDockForm.$values.on(getDockFx.doneData, (_, data) =>
  parseFormDataFromApi(data as any)
);

$editedDock
  .on(editDockFx.done, (_, { params }) => params)
  .reset(editFormGate.close);

sample({
  clock: editFormGate.open,
  target: getDockFx,
});

sample({
  clock: editFormGate.open,
  target: getCitiesFx,
});

sample({
  // @ts-ignore
  clock: getDockFx.doneData,
  filter: (dock) => !!dock.image,
  fn: (dock) => dock.image,
  target: addImage,
});

sample({
  clock: imageUploaded,
  fn: (value) => ({ field: "image", value }),
  target: editDockForm.setValue,
});

export const $isEditDockFormSubmitting = editDockFx.pending;
