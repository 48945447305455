import styled from "styled-components";

import { ReactComponent as UnSuccessIcon } from "shared/ui/icons/unsuccess.svg";
import { Button } from "shared/ui/button";
import { SmallModalTemplate } from "shared/ui/modals";

export function LOAChangeModal(props: {
  isOpened: boolean;
  onClose(): void;
}): JSX.Element {
  return (
    <Root
      isOpened={props.isOpened}
      onClose={props.onClose}
      renderContent={() => (
        <>
          <Icon as={UnSuccessIcon} />
          <Message>LOA changed</Message>
          <SecondaryMessage>
            The LOA of the boat you entered is different from the LOA of the
            selected boat. Please make another query for available time slots
            for a new boat LOA.
          </SecondaryMessage>
        </>
      )}
      renderActions={() => <Button onClick={props.onClose}>Try again</Button>}
    />
  );
}

const Icon = styled.div`
  width: 154px;
  height: 154px;
`;

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #0a1128;
`;

const Message = styled.div`
  width: 280px;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;

const SecondaryMessage = styled.div``;

const Root = styled(SmallModalTemplate)`
  width: 420px;

  ${Icon} {
    margin-bottom: 24px;
  }

  ${Title} {
    margin-bottom: 32px;
  }

  ${Message} {
    margin-bottom: 32px;
  }

  ${SecondaryMessage} {
    margin-bottom: 12px;
  }
`;
