import { useHistory } from "react-router";
import { useStore } from "effector-react";

import { AuthPageTemplate } from "shared/ui/templates/auth-page-template";
import {
  $newPasswordSubmitted,
  NewPasswordForm,
  NewPasswordSuccessModal,
} from "features/password-recovery";
import { MetaTags } from "shared/lib/metaTags";

export function NewPasswordPage(): JSX.Element {
  const history = useHistory();
  const formSubmitted = useStore($newPasswordSubmitted);

  return (
    <>
      <MetaTags title="New password" />
      <AuthPageTemplate
        header={"Welcome to dock management"}
        renderContent={() => <NewPasswordForm />}
      />

      <NewPasswordSuccessModal
        isOpened={formSubmitted}
        onClose={() => history.push("/")}
      />
    </>
  );
}
