import * as typed from "typed-contracts";

import { PostDockCollection, getDockItemOk } from "shared/api";

import {
  convertHoursFromArrayToMap,
  convertHoursMapToArray,
  FormHours,
} from "entities/docks/lib/operational-hours";

import { convertDateToApi } from "../../../../shared/lib/dayjs-ext";

export type DockApiInput = PostDockCollection["body"] & {
  addons: {
    addon: string;
    description?: string;
    price: string;
    priceType: string;
    tax?: string | null;
    taxType?: string | null;
  }[];
};

export type DockFromApi = typed.Get<typeof getDockItemOk> & {
  addons: Array<{
    addon: string;
    description: string;
    price: string;
    priceType: string;
    tax?: string | null;
    taxType?: string | null;
  }>;
  outOfSeasonTimeFrom: string;
  outOfSeasonTimeTo: string;
};

export interface DockForm {
  image: string;
  name: string;
  description: string;
  address: string;
  city: string;
  cityObject: string;
  state: string;
  phone: string;
  email: string;
  length: number | null;
  zipCode: string;
  days: FormHours;
  outOfSeasonTimeFrom?: string | null;
  outOfSeasonTimeTo?: string | null;
  defaultPrice: number | null;
  prices?: Array<{
    lengthFrom?: number;
    lengthTo?: number;
    price?: number;
  }>;
  restaurants?: Array<{
    type?: string;
    name?: string;
    url?: string;
    distance?: number | null;
  }>;
  addons?: Array<{
    addon?: string;
    description?: string;
    price?: string;
    priceType?: string;
    taxable?: boolean;
    tax?: string | null;
    taxType?: string | null;
  }>;
}
export interface DockValidForm extends DockForm {
  length: number;
  defaultPrice: number;
  taxRate: number;
  outOfSeasonTimeFrom: string;
  outOfSeasonTimeTo: string;
  prices: Array<{
    lengthFrom: number;
    lengthTo: number;
    price: number;
  }>;
  restaurants: Array<{
    type: string;
    name: string;
    url?: string;
    distance?: number | null;
  }>;
  addons: Array<{
    addon: string;
    description?: string;
    price: string;
    priceType: string;
    tax?: string | null;
    taxType?: string | null;
    taxable: boolean;
  }>;
}

export function parseFormDataFromApi(dockFromApi: DockFromApi): DockValidForm {
  const {
    operationalHours,
    image,
    defaultPrice,
    taxRate,
    outOfSeasonTimeFrom,
    outOfSeasonTimeTo,
    prices,
    restaurants,
    addons,
    address,
    city,
    cityObject,
    length,
    name,
    description,
    phone,
    email,
    state,
    zipCode,
  } = dockFromApi;

  return {
    address,
    city: city || cityObject?.name || "",
    cityObject: cityObject?.["@id"] || "",
    state: state || "",
    length,
    name,
    description: description || "",
    phone,
    email,
    zipCode,
    days: convertHoursFromArrayToMap((operationalHours as any[]) ?? []),
    image: image?.["@id"] ?? "",
    defaultPrice: parsePrice(defaultPrice),
    taxRate: parsePrice(taxRate),
    outOfSeasonTimeFrom,
    outOfSeasonTimeTo,
    prices:
      prices?.map((priceItem) => ({
        ...priceItem,
        price: parsePrice(priceItem.price),
      })) ?? [],
    restaurants:
      restaurants?.map((restaurant) => ({
        type: restaurant.type ?? "",
        name: restaurant.name,
        url: restaurant.url ?? "",
        distance: restaurant.distance ?? null,
      })) ?? [],
    addons:
      addons?.map((addon) => ({
        id: addon["@id"],
        addon: addon.addon["@id"] ?? "",
        description: addon.description ?? "",
        price: addon.price ?? "",
        priceType: addon.priceType ?? "",
        taxable: Boolean(addon.tax && addon.taxType),
        tax: addon.tax as string,
        taxType: addon.taxType as string,
      })) ?? [],
  };
}

function parsePrice(priceString: string): number {
  return parseFloat(priceString);
}

export function formatFormDataToApi(dockFormData: DockValidForm): DockApiInput {
  const {
    days,
    image,
    taxRate,
    defaultPrice,
    outOfSeasonTimeFrom,
    outOfSeasonTimeTo,
    prices,
    restaurants,
    addons,
    address,
    city,
    cityObject,
    length,
    name,
    description,
    phone,
    email,
    state,
    zipCode,
  } = dockFormData;

  return {
    operationalHours: convertHoursMapToArray(days),
    image,
    defaultPrice: defaultPrice.toString(),
    taxRate: parseFloat(taxRate + "").toFixed(2),
    outOfSeasonTimeFrom: outOfSeasonTimeFrom
      ? convertDateToApi(outOfSeasonTimeFrom)
      : void 0,
    outOfSeasonTimeTo: outOfSeasonTimeTo
      ? convertDateToApi(outOfSeasonTimeTo)
      : void 0,
    prices: prices
      .filter((priceItem) => priceItem.price >= 0)
      .map((priceItem) => ({
        ...priceItem,
        price: priceItem.price.toString(),
      })),
    restaurants: restaurants
      .filter((restaurant) => restaurant.name)
      .map((restaurant) => ({
        ...restaurant,
        distance: parseFloat(restaurant.distance + ""),
      })),
    // @ts-ignore
    addons: addons
      .filter((addon) => addon.price)
      .map((addon) => {
        return {
          addon: addon.addon,
          description: addon.description,
          price: addon.price.toString(),
          priceType: addon.priceType,
          tax: addon.taxable ? addon.tax?.toString() : null,
          taxType: addon.taxable ? addon.taxType : null,
        };
      }),
    address,
    city,
    cityObject,
    length,
    name,
    description,
    phone,
    email,
    state,
    zipCode,
  };
}
