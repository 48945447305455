import styled, { css } from "styled-components";
import { useGate, useUnit } from "effector-react";

import { Client } from "shared/api/types";
import { SearchInput } from "shared/ui";
import { SuspiciousTooltip } from "@manager-app/features/manage-suspicious-users";

import {
  searchInputChanged,
  $searchInput,
  $customersList,
  customerLineClicked,
  $selectedCustomer,
  $isCustomerSelected,
  Gate,
} from "../model";

function CustomerSelectComponent(props: {
  className?: string;
  disabled?: boolean;
}): JSX.Element {
  useGate(Gate);
  const [searchQuery, customersList, isCustomerSelected, selectedCustomer] =
    useUnit([
      $searchInput,
      $customersList,
      $isCustomerSelected,
      $selectedCustomer,
    ]);
  return !isCustomerSelected ? (
    <Root>
      <SearchInput
        className={props.className}
        disabled={props.disabled}
        label="Enter phone / email / full name"
        value={searchQuery ? searchQuery : ""}
        onChange={searchInputChanged}
      />
      {customersList?.length > 0 && (
        <CustomersList>
          {customersList.map((customer) => (
            <CustomerLine
              key={customer["@id"]}
              customer={customer}
              onClick={() => customerLineClicked(customer)}
            />
          ))}
        </CustomersList>
      )}
    </Root>
  ) : (
    <CustomerLine customer={selectedCustomer} isSelected={true} />
  );
}

export const CustomerSelectField = styled(CustomerSelectComponent)`
  width: 322px;
`;

function CustomerLineComponent(props: {
  customer: Client | null;
  onClick?(): void;
  className?: string;
  isSelected?: boolean;
}): JSX.Element {
  const { customer } = props;
  return (
    <div className={props?.className} onClick={props.onClick}>
      <CustomerName
        isSelected={props.isSelected ?? false}
        isSuspicious={props.customer?.isSuspicious}
      >
        {`${customer?.firstName} ${customer?.lastName}`}
        {props.customer?.isSuspicious && <SuspiciousTooltip />}
      </CustomerName>
      <Delimiter />
      <InfoField>{customer?.phone}</InfoField>
      <Delimiter />
      <InfoField>{customer?.email}</InfoField>
    </div>
  );
}

const CustomerName = styled.div<{ isSelected: boolean; isSuspicious: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #5e88ef;
  font-weight: 600;
  font-size: ${(props) => (props.isSelected ? "16px" : "18px")};
  line-height: ${(props) => (props.isSelected ? "18px" : "20px")};
  ${(props) =>
    props.isSuspicious &&
    css`
      color: #de2016;
    `}
  ${SuspiciousTooltip} {
    width: 20px;
    height: 20px;
  }
`;

const InfoField = styled.div``;

export const CustomerLine = styled(CustomerLineComponent)`
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
`;

const Delimiter = styled.div`
  width: 6px;
  height: 6px;
  background: #b0e9e9;
  border-radius: 50%;
`;

const CustomersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 6px 0;
  background: #f7f9fc;
  border: 1px solid #e7ecf7;
  border-radius: 12px;
  ${CustomerLine} {
    padding: 6px 16px;
  }
`;

const Root = styled.div`
  ${CustomersList} {
    margin-top: 12px;
  }
`;
