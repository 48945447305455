import * as typed from "typed-contracts";
import { GenericErrors } from "shared/api";
import { createEffect } from "effector";
import { parseByStatus, requestFx } from "shared/api/request";

type PostBoatCollection = {
  body: {
    client: string;
    name: string;
    manufacturer: string;
    model: string;
    year: number;
    loa: number;
    beam: number;
    draft?: number | null;
    height?: number | null;
  };
};
type PostBoatCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postBoatCollectionCreated>;
};

type PostBoatCollectionFail =
  | {
      status: "bad_request";
      error: typed.Get<typeof postBoatCollectionBadRequest>;
    }
  | {
      status: "unprocessable_entity";
      error: typed.Get<typeof postBoatCollectionUnprocessableEntity>;
    }
  | GenericErrors;

const postBoatCollectionCreated = typed.object({
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
  manufacturer: typed.string,
  model: typed.string,
  year: typed.number,
  loa: typed.number,
  beam: typed.number,
  draft: typed.number.maybe,
  height: typed.number.maybe,
  isMain: typed.boolean.optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.optional,
});

const postBoatCollectionBadRequest = typed.nul;
const postBoatCollectionUnprocessableEntity = typed.nul;

export const postBoatCollectionByManager = createEffect<
  PostBoatCollection,
  PostBoatCollectionDone,
  PostBoatCollectionFail
>({
  async handler({ body }) {
    const name = "postBoatCollection.body";
    const response = await requestFx({
      path: "/api/boats/by-manager",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postBoatCollectionCreated],
      400: ["bad_request", postBoatCollectionBadRequest],
      422: ["unprocessable_entity", postBoatCollectionUnprocessableEntity],
    });
  },
});
