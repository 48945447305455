import React from "react";
import styled from "styled-components";

import { LinkButton } from "shared/ui/button";
import { ArrowLeftIcon } from "shared/ui//icons";

export function ReservationsPageTemplate(props: {
  children: React.ReactNode;
  backLink: string;
}): JSX.Element {
  return (
    <Root>
      <BackButton as={LinkButton} to={props.backLink}>
        <BackIcon as={ArrowLeftIcon} />
      </BackButton>
      {props.children}
    </Root>
  );
}

const BackIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const BackButton = styled.div`
  width: 44px !important;
  & > * {
    padding: 0 !important;
    min-width: 0 !important;
  }
`;
const Root = styled.div`
  ${BackButton} {
    margin-bottom: 24px;
  }
`;
