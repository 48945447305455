import { attach, createEvent, createStore, sample } from "effector";
import { createGate } from "effector-react";
import { getListFromApi, getClientCollection } from "shared/api";
import { Client } from "shared/api/types";
import { debounce, reset } from "patronum";

export const Gate = createGate();
export const searchInputChanged = createEvent<string>();
export const customersListReset = createEvent();
const customerListUpdated = createEvent();
export const customerLineClicked = createEvent<Client>();
export const buttonCustomerChangeClicked = createEvent();
export const selectedCustomerChanged = createEvent<Client>();
export const customerReset = createEvent();

export const $selectedCustomer = createStore<Client | null>(null);
export const $isCustomerSelected = $selectedCustomer.map(Boolean);

export const $searchInput = createStore("");
export const $customersList = createStore<Client[]>([]);

const getCustomersFx = attach({
  // @ts-ignore
  effect: getListFromApi(getClientCollection),
  mapParams: (input) => ({
    query: { pagination: false, keyword: input },
  }),
});

sample({
  clock: getCustomersFx.done,
  source: $searchInput,
  filter: (inputValue, { params }) => {
    return inputValue === params;
  },
  fn: (_, { result }) => result.items,
  target: customerListUpdated,
});

$searchInput
  .on(searchInputChanged, (_, value) => value)
  .reset(buttonCustomerChangeClicked);

$customersList
  .on(customerListUpdated, (_, result) => result)
  .reset(customersListReset);

const minCharsToUpdate = 3;

sample({
  clock: debounce({ source: $searchInput, timeout: 500 }),
  filter: (inputValue) => inputValue.length >= minCharsToUpdate,
  target: getCustomersFx,
});

sample({
  clock: $searchInput,
  filter: (inputValue) => inputValue.length < minCharsToUpdate,
  target: customersListReset,
});

sample({
  clock: customerLineClicked,
  target: selectedCustomerChanged,
});

sample({
  clock: buttonCustomerChangeClicked,
  target: customerReset,
});

$selectedCustomer
  .on(selectedCustomerChanged, (_, value) => value)
  .reset(customerReset);

reset({
  clock: Gate.close,
  target: [$selectedCustomer, $searchInput, $customersList],
});
