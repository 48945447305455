import { createEffect } from "effector";

import { apiRequestFx } from "shared/api";

type ReservationWithClientParams = {
  dock: string;
  boat: string;
  client: string;
  timeFrom: string;
  timeTo: string;
  isCharter?: true;
  dockAddons?: string[];
  comment: string;
};

export type ReservationWithClient = {
  id: number;
  status: string;
};

export const postReservationWithClientCollection = createEffect(
  async (params: ReservationWithClientParams) => {
    const response: ReservationWithClient = await apiRequestFx({
      method: "POST",
      path: "/api/manager/reservations",
      body: params,
    });

    return response;
  }
);
