import { createEffect } from "effector";
import { apiRequestFx } from "shared/api";

type ChangeReservationBillingParams = {
  reservationId: string;
  billingInfo: {
    firstName: string;
    lastName: string;
    email: string;
    confirmEmail: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
  };
};

export const changeReservationBillingInfoFx = createEffect(
  async (params: ChangeReservationBillingParams) => {
    await apiRequestFx({
      method: "PUT",
      path: `/api/manager/reservations/${params.reservationId}/billing`,
      body: params.billingInfo,
    });
  }
);

type GetStripeKeyForPaymentParams = {
  reservationId: string;
  savePaymentMethod: boolean;
};

export const getStripeKeyForPayment = createEffect(
  async (params: GetStripeKeyForPaymentParams) => {
    const { clientSecret } = await apiRequestFx({
      method: "POST",
      path: `/api/manager/reservations/${params.reservationId}/pay`,
      body: {
        savePaymentMethod: params.savePaymentMethod,
      },
    });

    return clientSecret;
  }
);
