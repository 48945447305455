import { createEvent, createStore, sample } from "effector";

export const addonLineClicked = createEvent<string>();
export const isCharterClicked = createEvent();

export const addonsChanged = createEvent();
export const $addons = createStore<string[]>([]);
export const $isCharter = createStore(false);

sample({
  clock: addonLineClicked,
  source: $addons,
  fn: (addons, addon) =>
    addons.includes(addon)
      ? addons.filter((value) => value !== addon)
      : [...addons, addon],
  target: addonsChanged,
});

$addons.on(addonsChanged, (_, addons) => addons);

$isCharter.on(isCharterClicked, (state) => !state);
