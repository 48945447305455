import { attach, combine, createEvent, createStore } from "effector";
import { createGate } from "effector-react";

import { getListFromApi } from "shared/api";
import { pagination, spreadListDataData } from "shared/lib/effector-paginatoin";
import { Range } from "shared/ui/ecosystems/forms";
import { convertToApi, dayjs } from "shared/lib/dayjs-ext";
import { getReportCollection } from "entities/reports/api";
import { ReportLine } from "entities/reports/types";

export const pageGate = createGate();

export const $reportLines = createStore<ReportLine[]>([]);
export const $reportLinesTotalCount = createStore(0);

export const $currentPage = createStore(1);
export const $itemsPerPage = createStore(10);

export const pageChanged = createEvent<number>();
export const itemsPerPageChanged = createEvent<number>();

export const $dateOfReservation = createStore<Range>({
  startDate: dayjs().subtract(1, "month").startOf("month").toDate(),
  endDate: dayjs().subtract(1, "month").endOf("month").toDate(),
});

export const dateOfReservationChanged = createEvent<Range>();

export const $dock = createStore<string | null>(null);
export const dockChanged = createEvent<string>();

export const $filters = combine(
  $itemsPerPage,
  $dateOfReservation,
  $dock,
  (itemsPerPage, dateOfReservation, dock) => ({
    dock,
    itemsPerPage,
    "timeFrom[strictly_before]": dateOfReservation.endDate
      ? convertToApi(dayjs(dateOfReservation.endDate).add(1, "day"))
      : null,
    "timeTo[after]": dateOfReservation.startDate
      ? convertToApi(dateOfReservation.startDate)
      : null,
  })
);

$currentPage.on(pageChanged, (_, page) => page);
$itemsPerPage.on(itemsPerPageChanged, (_, value) => value);

const getReportListFx = attach({
  // @ts-ignore
  effect: getListFromApi(getReportCollection, {
    ignoreValidation: true,
  }),
  mapParams: (query) => ({
    query,
  }),
});

$dateOfReservation.on(dateOfReservationChanged, (_, value) => value);
$dock.on(dockChanged, (_, value) => value);

spreadListDataData({
  $items: $reportLines,
  $totalCount: $reportLinesTotalCount,
  effect: getReportListFx,
});

pagination({
  $page: $currentPage,
  $filters: $filters,
  // @ts-ignore
  effect: getReportListFx,
  gate: pageGate,
});
