import { createGate } from "effector-react";
import { attach, combine, createEvent, createStore } from "effector";
import { getDockCollection, getListFromApi } from "shared/api";
import { pagination, spreadListDataData } from "shared/lib/effector-paginatoin";

import { Dock } from "entities/docks";
import { dockDeleted } from "@manager-app/features/manage-docks";

interface Filters {
  itemsPerPage: number;
  search: string | null;
}

export const pageGate = createGate();

export const $docksList = createStore<Dock[]>([]);
export const $docksTotalCount = createStore(0);

export const $currentPage = createStore(1);
export const $itemsPerPage = createStore(10);
export const $searchQuery = createStore<string | null>(null);

export const pageChanged = createEvent<number>();
export const itemsPerPageChanged = createEvent<number>();
export const searchQueryChanged = createEvent<string>();

const $filters = combine({
  itemsPerPage: $itemsPerPage,
  search: $searchQuery,
});

$currentPage.on(pageChanged, (_, page) => page);
$searchQuery.on(searchQueryChanged, (_, value) => value).reset(pageGate.close);
$itemsPerPage.on(itemsPerPageChanged, (_, value) => value);

const getDocksListFx = attach({
  // @ts-ignore
  effect: getListFromApi(getDockCollection, { ignoreValidation: true }),
  mapParams: (query: Filters & { page: number }) => ({
    query,
  }),
});

spreadListDataData({
  $items: $docksList,
  $totalCount: $docksTotalCount,
  effect: getDocksListFx,
});

pagination({
  $page: $currentPage,
  $filters: $filters,
  effect: getDocksListFx,
  gate: pageGate,
  reloadTrigger: dockDeleted,
});
