import styled from "styled-components";
import { ReactNode } from "react";

import { mediaPhone } from "shared/ui/styles/media";

export interface Props {
  renderFields(): ReactNode;
  renderActions?(): ReactNode;
}

export function FormTemplate(props: Props): JSX.Element {
  return (
    <FormTemplateRoot>
      <Fields>{props.renderFields()}</Fields>
      {props.renderActions && <Actions>{props.renderActions()}</Actions>}
    </FormTemplateRoot>
  );
}

const Fields = styled.div``;

const Actions = styled.div`
  ${mediaPhone} {
    button {
      width: 100%;
    }
  }
`;

const FormTemplateRoot = styled.div`
  ${Actions} {
    margin-top: 24px;
  }
`;
