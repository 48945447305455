import { Button as MaterialButton } from "@mui/material";
import styled from "styled-components";
import React from "react";

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  href?: string;
  onClick?(): void;
}

function TextButtonComponent(props: Props): JSX.Element {
  const { ...buttonProps } = props;
  return (
    <MaterialButton variant="text" {...buttonProps}>
      {props.children}
    </MaterialButton>
  );
}

export const TextButton = styled(TextButtonComponent)``;
