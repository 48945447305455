import { createEffect } from "effector";
import { parseByStatus, requestFx } from "shared/api/request";
import { GenericErrors } from "shared/api/index";
import * as typed from "typed-contracts";

type GetAddonCollection = {
  query?: {
    page?: number;
    itemsPerPage?: number;
    pagination?: boolean;
  };
};

const getAddonCollectionOk = typed.object({
  id: typed.number,
  name: typed.string,
});

type GetAddonCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getAddonCollectionOk>;
};
type GetAddonCollectionFail = GenericErrors;

export const getAddonCollection = createEffect<
  GetAddonCollection,
  GetAddonCollectionDone,
  GetAddonCollectionFail
>({
  async handler({ query }) {
    const name = "getAddonCollection.body";
    const response = await requestFx({
      path: "/api/addons",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getAddonCollectionOk],
    });
  },
});
