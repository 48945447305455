import styled from "styled-components";

import { AttentionIcon } from "shared/ui/icons";
import { Button } from "shared/ui/button";
import { SmallModalTemplate } from "shared/ui/modals";

export function SuperOverrideModal(props: {
  isOpened: boolean;
  onCancel(): void;
  onConfirm(): void;
}): JSX.Element {
  return (
    <Root
      isOpened={props.isOpened}
      onClose={props.onCancel}
      renderContent={() => (
        <>
          <Icon as={AttentionIcon} />
          <Message>Super override!</Message>
          <SecondaryMessage>
            Warning: You are about to make an override. Please be sure that
            there is enough dock space available during this time or that a
            rafting arrangement has been made.
          </SecondaryMessage>
        </>
      )}
      renderActions={() => (
        <Actions>
          <Button variant="outlined" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button onClick={props.onConfirm}>Yes, apply</Button>
        </Actions>
      )}
    />
  );
}

const Icon = styled.div`
  width: 148px;
  height: 154px;
`;

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #0a1128;
`;

const Message = styled.div`
  width: 280px;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;

const SecondaryMessage = styled.div``;

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const Root = styled(SmallModalTemplate)`
  width: 420px;

  ${Icon} {
    margin-bottom: 24px;
  }

  ${Title} {
    margin-bottom: 32px;
  }

  ${Message} {
    margin-bottom: 32px;
  }

  ${SecondaryMessage} {
    margin-bottom: 12px;
  }
`;
