import { attach, createEvent, createStore, sample } from "effector";
import { createGate } from "effector-react";

import { createRouteParamStore } from "shared/lib/effector-router-params";
import { createOpenAbleState } from "shared/lib/effector-openable-state";
import {
  getReservationItem,
  postPayReservationAtDockFx,
  Billing,
} from "entities/reservations/api";
import { changeReservationBillingInfoFx } from "@manager-app/features/pay-for-reservation/api";
import {
  createBillingForm,
  $reservation,
  paymentDone,
  paymentFail,
} from "@manager-app/features/pay-for-reservation";

export const reservationPayPageGate = createGate();

export const $reservationId = createRouteParamStore({
  name: "reservationId",
  gate: reservationPayPageGate,
});

export { $reservation };

export const buttonSaveClicked = createEvent();

export const $paymentType = createStore<"atDock" | "onLine">("atDock");
export const paymentTypeChanged = createEvent();
const $billing = createStore<Billing | null>(null);

export const [successPayInfoModal, successPayInfoModalActions] =
  createOpenAbleState();
export const [unSuccessPayInfoModal, unSuccessPayInfoModalActions] =
  createOpenAbleState();

export const [successPaymentModal, successPaymentModalActions] =
  createOpenAbleState();
export const [unSuccessPaymentModal, unSuccessPaymentModalActions] =
  createOpenAbleState();

const getReservationFx = attach({
  effect: getReservationItem,
  source: $reservationId,
  mapParams: (_, reservationId) => ({ id: reservationId as string }),
});

const postPayAtDockFx = attach({
  effect: postPayReservationAtDockFx,
  source: $reservationId,
  mapParams: (_, reservationId) => ({ id: reservationId as string }),
});

const submitBillingDataFx = attach({
  effect: changeReservationBillingInfoFx,
  source: $reservationId,
  mapParams: (billing, reservationId) => ({
    reservationId,
    billingInfo: { ...billing, zipCode: billing.zipCode.toString() },
  }),
});

export const buttonBillingChangeClicked = createEvent();
export const payInfoSaved = postPayAtDockFx.done;

sample({
  source: $reservation,
  fn: (reservation) => reservation.billing,
  target: $billing,
});

sample({
  clock: payInfoSaved,
  target: successPayInfoModalActions.open,
});

sample({
  clock: postPayAtDockFx.failData,
  target: unSuccessPayInfoModalActions.open,
});

sample({
  clock: paymentDone,
  target: successPaymentModalActions.open,
});

sample({
  clock: paymentFail,
  target: unSuccessPaymentModalActions.open,
});

$paymentType.on(paymentTypeChanged, (_, paymentType) => paymentType);

sample({
  clock: reservationPayPageGate.open,
  source: $reservationId,
  target: getReservationFx,
});

sample({
  clock: buttonSaveClicked,
  source: $paymentType,
  filter: (paymentType) => paymentType === "atDock",
  target: postPayAtDockFx,
});

$reservation.on(getReservationFx.doneData, (_, reservation) => reservation);

export const {
  form: billingForm,
  $billingSubmitted,
  $billingSaved,
} = createBillingForm({
  onSubmit: submitBillingDataFx,
  $billing: $billing,
});

$billingSaved.reset(buttonBillingChangeClicked);
