import styled from "styled-components";
import { useGate, useUnit } from "effector-react";

import { Boat, Client } from "shared/api/types";

import boatImg from "./boat.svg";

import {
  $boatsList,
  boatLineClicked,
  $selectedBoat,
  $isBoatSelected,
  Gate,
} from "../../models/select-boat-model";

function BoatSelectComponent(props: {
  customer: Client;
  className?: string;
  disabled?: boolean;
}): JSX.Element {
  useGate(Gate, props.customer);
  const [boatsList, isBoatSelected, selectedBoat] = useUnit([
    $boatsList,
    $isBoatSelected,
    $selectedBoat,
  ]);
  return !isBoatSelected ? (
    <Root>
      {boatsList?.length > 0 && !props.disabled && (
        <BoatsList>
          {boatsList.map((boat) => (
            <BoatsListLine
              key={boat["@id"]}
              boat={boat}
              onClick={() => boatLineClicked(boat)}
            />
          ))}
        </BoatsList>
      )}
    </Root>
  ) : (
    <BoatLine boat={selectedBoat} isBoatSelected={true} />
  );
}

export const BoatSelectField = styled(BoatSelectComponent)`
  width: 322px;
`;

function BoatLineComponent(props: {
  boat: Boat | null;
  onClick?(): void;
  className?: string;
  isBoatSelected?: boolean;
}): JSX.Element {
  const { boat } = props;
  return (
    <LineRoot
      className={props?.className}
      onClick={props.onClick}
      isSelected={props?.isBoatSelected}
    >
      <NameBlock>
        {!props.isBoatSelected && (
          <BoatImage
            src={props.boat?.image ? props.boat.image.url : boatImg}
            alt=""
          />
        )}
        <Model isSelected={props.isBoatSelected}>
          <BoatName isSelected={props?.isBoatSelected}> {boat?.name} </BoatName>
          {!props.isBoatSelected && (
            <>
              <InfoField>{boat?.manufacturer}</InfoField>
              <InfoField>{`${boat?.model}, ${boat?.year}`}</InfoField>
            </>
          )}
        </Model>
      </NameBlock>
      <SpecificationsBlock>
        {props.isBoatSelected && <Delimiter />}
        <SpecificationField
          name="LOA"
          value={`${boat?.loa} ft`}
          isSelected={props.isBoatSelected}
        />
        <Delimiter />
        <SpecificationField
          name="Beam"
          value={boat?.beam}
          isSelected={props.isBoatSelected}
        />
        <Delimiter />
        <SpecificationField
          name="Draft"
          value={boat?.draft ? `${boat?.draft} ft` : "-"}
          isSelected={props.isBoatSelected}
        />
        <Delimiter />
        <SpecificationField
          name="Height"
          value={boat?.height ? `${boat?.height} ft` : "-"}
          isSelected={props.isBoatSelected}
        />
      </SpecificationsBlock>
    </LineRoot>
  );
}

function SpecificationField(props: {
  name: string;
  value: string | null;
  isSelected?: boolean;
}): JSX.Element {
  return (
    <SpecificationWrapper isSelected={props?.isSelected}>
      <SpecificationName>
        {props.isSelected ? `${props.name}:` : `${props.name}`}
      </SpecificationName>
      <SpecificationValue>{props.value}</SpecificationValue>
    </SpecificationWrapper>
  );
}

const SpecificationName = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5e88ef;
`;

const SpecificationValue = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;

const SpecificationWrapper = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isSelected ? "row" : "column")};
  gap: ${(props) => (props.isSelected ? "4px" : "2px")};
  align-items: center;
`;

const BoatImage = styled.img`
  aspect-ratio: 16/9;
  border-radius: 8px;
  height: 100%;
`;

const BoatName = styled.div<{ isSelected?: boolean }>`
  color: #5e88ef;
  font-weight: 600;
  font-size: ${(props) => (props.isSelected ? "16px" : "18px")};
  line-height: ${(props) => (props.isSelected ? "18px" : "20px")};
`;

const NameBlock = styled.div`
  display: flex;
  height: 100%;
  gap: 16px;
  max-width: 398px;
`;

const Delimiter = styled.div`
  width: 6px;
  height: 6px;
  background: #b0e9e9;
  border-radius: 50%;
`;

const SpecificationsBlock = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-start;
  ${Delimiter} {
    align-self: center;
  }
`;

const InfoField = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
`;

const Model = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  ${BoatName} {
    margin-bottom: ${(props) => (props.isSelected ? "0" : "8px")};
  }
`;

const BoatsListLine = styled(BoatLineComponent)`
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;

  background: #f7f9fc;
  border: 1px solid #e7ecf7;
  border-radius: 12px;
`;

export const BoatLine = styled(BoatLineComponent)`
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
`;

const LineRoot = styled.div<{ isSelected?: boolean }>`
  display: flex;
  height: ${(props) => (props.isSelected ? "fit-content" : "104px")};
  width: 100%;
  padding: 4px;
  align-items: center;
  justify-content: ${(props) =>
    props.isSelected ? "flex-start" : "space-between"};
`;

const BoatsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 6px 0;
  ${BoatsListLine} {
    padding: 6px 16px;
  }
`;
const Root = styled.div`
  ${BoatsList} {
    margin-top: 12px;
  }
`;
