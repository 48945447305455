import { createGate } from "effector-react";
import { attach, createStore, sample } from "effector";
import { getListFromApi } from "shared/api";

import { getAddonCollection } from "@manager-app/shared/api/addons";
import { spreadListDataData } from "shared/lib/effector-paginatoin";

interface Addon {
  "@id": string;
  name: string;
}

export const Gate = createGate();
export const $addonsList = createStore<Addon[]>([]);

const getAddonsFx = attach({
  // @ts-ignore
  effect: getListFromApi(getAddonCollection),
  mapParams: () => ({}),
});

spreadListDataData({
  $items: $addonsList,
  effect: getAddonsFx,
});

sample({
  clock: Gate.open,
  target: getAddonsFx,
});
