import React from "react";
import { useGate, useStore, useUnit } from "effector-react";
import styled, { css } from "styled-components";

import { EditClientReservationModal } from "features/edit-client-reservation";
import { dayjs } from "shared/lib/dayjs-ext/dayjs-ext";
import { CustomerPageTemplate } from "shared/ui/templates/customer-detailed-page-template";
import { EditCommentIcon } from "shared/ui/icons";
import { mediaPhone } from "shared/ui/styles/media";
import { Client } from "shared/api/types";
import { MetaTags } from "shared/lib/metaTags";
import { createReservationsTable } from "features/reservation-searching";

import {
  ConfirmSuspiciousModal,
  SuspiciousTooltip,
  SuspiciousButton,
  setSuspicious,
} from "@manager-app/features/manage-suspicious-users";

import { $client, clientPageGate } from "./model";
import { EditCommentModal } from "./edit-comment/edit-comment-modal";
import {
  editCommentModalClosed,
  $isEditCommentModalOpened,
  buttonEditCommentClicked,
} from "./edit-comment/model";
import { getClientsReservationsFx } from "./model";

const ReservationsTable = createReservationsTable({
  getReservationsFx: getClientsReservationsFx,
  hidePagination: true,
});

export function CustomersListByIdPage(): JSX.Element {
  useGate(clientPageGate);

  const client = useUnit($client);
  return (
    <>
      <MetaTags title="Customer" />
      <CustomerPageTemplate
        client={client}
        renderClientCard={() => <ClientCard client={client} />}
        renderReservationsList={() => <ReservationsTable />}
        renderModalWindows={() => <EditClientReservationModal />}
        renderSuspiciousButton={() => (
          <SuspiciousButton
            isSuspicious={client?.isSuspicious}
            onClick={() => setSuspicious(client)}
          />
        )}
      />
      <ConfirmSuspiciousModal />
    </>
  );
}

function ClientCard(props: { client: Client | null }): JSX.Element {
  if (!props.client) return <></>;
  const isEditCommentModalOpened = useStore($isEditCommentModalOpened);

  return (
    <>
      <ClientInfo>
        <NameLine>
          <Name
            isSuspicious={props.client.isSuspicious!}
          >{`${props.client.firstName} ${props.client.lastName}`}</Name>
          {props.client.isSuspicious && <SuspiciousTooltip />}
        </NameLine>
        <Lines>
          <Line
            name="Phone number"
            value={
              <Contact href={`tel:${props.client.phone}`}>
                {props.client.phone}
              </Contact>
            }
          />
          <Line
            name="Email"
            value={
              <Contact href={`mailto:${props.client.email}`}>
                {props.client.email}
              </Contact>
            }
          />
          <Line
            name="Registration date"
            value={
              props.client.createdAt
                ? dayjs(props.client.createdAt).format("MM/DD/YYYY")
                : ""
            }
          />
          <Line name="Spent money" value={`$ ${0}`} />
        </Lines>
      </ClientInfo>
      <ClientComment>
        <CommentHeader>
          <LineName>Comment about client</LineName>
          <EditIcon
            as={EditCommentIcon}
            onClick={() => buttonEditCommentClicked()}
          />
        </CommentHeader>
        <LineValue>{props.client.comment ?? ""}</LineValue>
      </ClientComment>

      <EditCommentModal
        isOpened={isEditCommentModalOpened}
        onClose={editCommentModalClosed}
      />
    </>
  );
}

function Line(props: { name: string; value: string | React.ReactNode }) {
  return (
    <LineRoot>
      <LineName>{props.name}</LineName>
      <LineValue>{props.value}</LineValue>
    </LineRoot>
  );
}

const Contact = styled.a`
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a1128;
`;

const LineName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #969899;
`;

const LineValue = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a1128;
`;

const LineRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ClientComment = styled.div`
  padding: 8px 24px;
  position: relative;
  ${mediaPhone} {
    padding: 16px 0;
    border-bottom: 1px solid #e7ebf7;
  }
  :after {
    content: "";
    position: absolute;
    left: 0;
    top: -24px;
    height: calc(100% + 48px);
    width: 0;
    border-left: 1px solid #e7ebf7;
    ${mediaPhone} {
      display: none;
    }
  }
`;

const Name = styled.div<{ isSuspicious: boolean }>`
  display: flex;
  color: #5e88ef;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;

  ${(props) =>
    props.isSuspicious &&
    css`
      color: #de2016;
    `}
`;

const NameLine = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${SuspiciousTooltip} {
    width: 32px;
    height: 32px;
  }
`;

const ClientInfo = styled.div`
  ${NameLine} {
    margin-bottom: 24px;
    margin-right: 8px;
  }
`;

const EditIcon = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
