import { attach, createEvent, sample } from "effector";

import { deleteManagerItem } from "shared/api";

export const deleteManager = createEvent<number>();
export const managerDeleted = createEvent<number>();

const deleteManagerFx = attach({
  effect: deleteManagerItem,
  // @ts-ignore
  mapParams: (id: number) => ({ path: { id } }),
});

sample({
  clock: deleteManager,
  target: deleteManagerFx,
});

sample({
  clock: deleteManagerFx.done,
  fn: ({ params }) => params,
  target: managerDeleted,
});
