import { SelectField } from "./select-field";

export function TypePriceSelectField(props: {
  name: string;
  label: string;
}): JSX.Element {
  return (
    <SelectField
      name={props.name}
      label={props.label}
      options={[
        { label: "Dollar", value: "constant" },
        { label: "Percent", value: "percent" },
      ]}
    />
  );
}
