import { createForm } from "effector-react-form";
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";

import {
  createApiErrorStatusStore,
  fromApi,
  putClientItem,
  putClientItemOk,
} from "shared/api";
import { createGate } from "effector-react";
import * as typed from "typed-contracts";

type Client = typed.Get<typeof putClientItemOk> & { id: string };

export const editCommentForm = createForm({
  onSubmit: ({ values }) => editClientFx(values),
});

export const $modalClient = createStore<Client | null>(null);

export const FormEditGate = createGate("form edit gate");

// @ts-ignore
const editClientFx = attach({
  effect: createEffect(fromApi(putClientItem)),
  source: $modalClient,
  // @ts-ignore
  mapParams: (formData: Client, client: Client) => ({
    // @ts-ignore
    path: { id: client.id },
    body: formData,
  }),
});

sample({
  clock: FormEditGate.open,
  source: $modalClient,
  target: editCommentForm.$values,
});

export const $formSubmitting = editClientFx.pending;
export const $serverErrorStatus = createApiErrorStatusStore(putClientItem);

export const commentEdited = editClientFx.doneData;

export const buttonEditCommentClicked = createEvent();
export const editCommentModalClosed = createEvent();

export const $isEditCommentModalOpened = createStore(false);
$isEditCommentModalOpened.on(commentEdited, () => false);

$isEditCommentModalOpened.on(buttonEditCommentClicked, () => true);
$isEditCommentModalOpened.reset(editCommentModalClosed);
$serverErrorStatus.reset(editCommentModalClosed);
