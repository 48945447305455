import { createForm } from "effector-react-form";
import { createEffect, createEvent, createStore } from "effector";
import { object, string } from "yup";

import { createValidator } from "shared/lib/form";
import { createApiErrorStatusStore, postManagerCollection } from "shared/api";
import {
  phoneValidationError,
  requiredFieldValidationError,
} from "shared/config/error-text";

export const newManagerForm = createForm({
  name: "New Manager Form",
  initialValues: {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    password: "",
  },
  validate: createValidator(
    object({
      email: string()
        .email("Please, use a valid email address.")
        .required(requiredFieldValidationError),
      firstName: string().required(requiredFieldValidationError),
      lastName: string().required(requiredFieldValidationError),
      phone: string()
        .required(requiredFieldValidationError)
        .phone("US", phoneValidationError),
      password: string().required(requiredFieldValidationError),
    })
  ),
  onSubmit: ({ values }) => submitFormFx(values),
});

export const $formSubmitting = postManagerCollection.pending;

const submitFormFx = createEffect(
  async (formData: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
  }) => {
    await postManagerCollection({ body: formData });
  }
);

export const $serverErrorStatus = createApiErrorStatusStore(
  postManagerCollection
);

export const newManagerCreated = submitFormFx.done;

export const buttonAddClicked = createEvent();
export const modalAddClosed = createEvent();

export const $isModalAddOpened = createStore(false);
$isModalAddOpened.on(newManagerCreated, () => false);

$isModalAddOpened.on(buttonAddClicked, () => true);
$isModalAddOpened.reset(modalAddClosed);
