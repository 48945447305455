import {
  NewDockForm,
  DockFormTemplate,
} from "@manager-app/features/manage-docks";

export function DocsListAddPage(): JSX.Element {
  return (
    <DockFormTemplate title="Add new dock">
      <NewDockForm />
    </DockFormTemplate>
  );
}
