import styled from "styled-components";

import {
  InputField,
  LettersField,
  NumberField,
  PhoneField,
} from "shared/ui/ecosystems/forms";

export function BillingFormFields(): JSX.Element {
  return (
    <Fields>
      <FormFields>
        <InputField name="firstName" label="First name *" maxLength={35} />
        <InputField name="lastName" label="Last name *" maxLength={35} />
      </FormFields>

      <FormFields>
        <InputField
          type="email"
          name="email"
          label="Email address *"
          maxLength={150}
        />
        <InputField
          type="email"
          name="confirmEmail"
          label="Confirm email *"
          maxLength={150}
        />
      </FormFields>

      <FormFields>
        <InputField name="address1" label="Address 1 *" />
        <InputField name="address2" label="Address 2" />
      </FormFields>

      <FormFields>
        <LettersField name="city" label="City *" maxLength={50} />
        <LettersField name="state" label="State *" maxLength={25} />
      </FormFields>

      <FormFields>
        <NumberField name="zipCode" label="Postal Code *" />
      </FormFields>
      <FormFields>
        <PhoneField name="phone" label="Phone number *" />
      </FormFields>
      <FormFields></FormFields>
    </Fields>
  );
}

const FormFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`;
