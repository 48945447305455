import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import {
  FtInputField,
  InputField,
  SubmitButton,
} from "shared/ui/ecosystems/forms";
import { mediaPhone } from "shared/ui/styles/media";

import { AutocompleteField } from "shared/ui/ecosystems/forms";

import { Form } from "shared/lib/form";

import {
  $isFormSubmitting,
  addBoatForm,
  $manufacturersList,
  $modelsList,
  $yearsList,
  Gate,
} from "../model";

export function AddBoatForm(props: { customer: Client }): JSX.Element {
  useGate(Gate, props.customer);
  const isFormSubmitting = useStore($isFormSubmitting);
  const manufacturersList = useStore($manufacturersList);
  const modelsList = useStore($modelsList);
  const yearsList = useStore($yearsList);

  return (
    <Root>
      <Form form={addBoatForm}>
        <Content>
          <FormFields>
            <InputField name="name" label="Enter boat name *" maxLength={30} />
            <AutocompleteField
              name="manufacturer"
              label="Select manufacturer *"
              options={manufacturersList}
            />

            <AutocompleteField
              name="model"
              label="Select model *"
              options={modelsList}
            />
            <AutocompleteField
              name="year"
              label="Select year *"
              options={yearsList}
            />

            <FtInputField name="loa" label="LOA *" min={0} maxLength={3} />
            <FtInputField name="beam" label="Beam *" min={0} maxLength={3} />

            <FtInputField name="draft" label="Draft" />
            <FtInputField name="height" label="Height" />
          </FormFields>
        </Content>
        <Actions>
          <SubmitButton isSubmitting={isFormSubmitting}>Next step</SubmitButton>
        </Actions>
      </Form>
    </Root>
  );
}

const FormFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-bottom: 32px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;
const Content = styled.div``;

const Actions = styled.div`
  display: flex;
  justify-content: center;

  ${SubmitButton}:only-child {
    width: 322px;

    ${mediaPhone} {
      width: 100%;
    }
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${Content} {
    margin-bottom: 32px;
  }
`;
