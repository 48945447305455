import { RouteConfig } from "react-router-config";

import { redirect } from "shared/lib/react-router";

import { SignInPage } from "../pages/sign-in";
import { ForgotPasswordPage } from "../pages/forgot-password";
import { NewPasswordPage } from "../pages/new-password";
import { DocsListPage } from "../pages/docks-list";
import { DocsListAddPage } from "../pages/docks-list/add";
import { DocsListByIdEditPage } from "../pages/docks-list/$dockId/edit";
import { CustomersListPage } from "../pages/customers-list";
import { CustomersListByIdPage } from "../pages/customers-list/$customerId";
import { ReservationsListPage } from "../pages/reservations-list";
import { DockManagerListPage } from "../pages/dock-manager-list";
import { ReservationPayPage } from "../pages/reservations-list/$reservationId/pay";
import { ReservationsListAddPage } from "../pages/reservations-list/add";
import { TermsAndConditionsDockEditPage } from "../pages/docks-list/$dockId/terms-and-conditions-edit";
import { ReportsPage } from "../pages/reports";

export const noAuthRoutes: RouteConfig[] = [
  {
    exact: true,
    path: "/",
    component: SignInPage,
  },
  {
    exact: true,
    path: "/sign-in",
    component: SignInPage,
  },
  {
    exact: true,
    path: "/forgot-password",
    component: ForgotPasswordPage,
  },
  {
    exact: true,
    path: "/new-password",
    component: NewPasswordPage,
  },
  {
    component: redirect("/"),
  },
];
export const authRoutes: RouteConfig[] = [
  {
    exact: true,
    path: "/docks-list",
    component: DocsListPage,
  },
  {
    exact: true,
    path: "/docks-list/add",
    component: DocsListAddPage,
  },
  {
    exact: true,
    path: "/docks-list/:dockId/edit",
    component: DocsListByIdEditPage,
  },
  {
    exact: true,
    path: "/docks-list/:dockId/terms/edit",
    component: TermsAndConditionsDockEditPage,
  },
  {
    exact: true,
    path: "/customers-list",
    component: CustomersListPage,
  },
  {
    exact: true,
    path: "/customers-list/:customerId",
    component: CustomersListByIdPage,
  },
  {
    exact: true,
    path: "/reservations-list",
    component: ReservationsListPage,
  },
  {
    exact: true,
    path: "/reservations-list/add",
    component: ReservationsListAddPage,
  },
  {
    exact: true,
    path: "/reservations-list/:reservationId/pay",
    component: ReservationPayPage,
  },
  {
    exact: true,
    path: "/dock-manager-list",
    component: DockManagerListPage,
  },
  {
    exact: true,
    path: "/reports",
    component: ReportsPage,
  },
  {
    component: redirect("/reservations-list"),
  },
];
