import { configure } from "features/password-recovery";
import {
  postManagerForgotPassword,
  postManagerForgotPasswordToken,
} from "shared/api";

export function init(): void {
  configure({
    forgotPasswordFx: postManagerForgotPassword,
    createNewPasswordFx: postManagerForgotPasswordToken,
  });
}
