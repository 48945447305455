import { Form } from "shared/lib/form";
import { useGate, useStore } from "effector-react";

import { FormFields } from "./dock-form/form-fields";
import {
  editDockForm,
  fieldArray,
  editFormGate,
  $isEditDockFormSubmitting,
  $cities,
} from "../model/edit-dock-form.model";
import { DockEditSuccess } from "./dock-form-success-modals";

export function EditDockForm(): JSX.Element {
  useGate(editFormGate);
  const isFormSubmitting = useStore($isEditDockFormSubmitting);

  const cities = useStore($cities);

  return (
    <Form form={editDockForm} fieldArray={fieldArray}>
      <FormFields
        submitText="Save dock"
        isSubmitting={isFormSubmitting}
        cities={cities}
      />
      <DockEditSuccess />
    </Form>
  );
}
