import { attach, createStore, Effect, sample, Store } from "effector";
import { createForm } from "effector-react-form";
import { object, ref, string } from "yup";

import { createValidator } from "shared/lib/form";

import {
  phoneValidationError,
  requiredFieldValidationError,
} from "shared/config/error-text";
import { Billing } from "entities/reservations/api";

const requiredString = string()
  .nullable()
  .required(requiredFieldValidationError);

interface BillingFormParams {
  onSubmit: Effect<any, any>;
  $billing: Store<Billing | null>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createBillingForm(params: BillingFormParams) {
  const billingForm = createForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
    },
    validate: createValidator(
      object({
        firstName: string().required(requiredFieldValidationError),
        lastName: string().required(requiredFieldValidationError),
        email: string()
          .email(" ")
          .required(requiredFieldValidationError)
          .oneOf([ref("confirmEmail")], "Emails must match"),
        confirmEmail: string()
          .email()
          .required(requiredFieldValidationError)
          .oneOf([ref("email")], "Emails must match"),
        address1: requiredString,
        city: requiredString,
        state: requiredString,
        zipCode: requiredString,
        phone: string()
          .phone("US", phoneValidationError)
          .required(requiredFieldValidationError),
      })
    ),
    onSubmit: async ({ values }) => {
      await formSubmitFx(values);
    },
  });

  const $billingSaved = createStore(false);

  sample({
    source: params.$billing,
    fn: (billing) => ({ ...billing, confirmEmail: billing?.email }),
    target: billingForm.setValues,
  });

  const formSubmitFx = attach({
    effect: params.onSubmit,
  });

  const $billingSubmitted = formSubmitFx.pending;
  $billingSaved.on(formSubmitFx.done, () => true);

  return {
    form: billingForm,
    $billingSubmitted,
    $billingSaved,
  };
}
