import styled from "styled-components";
import { Box } from "@mui/material";

import {
  CheckBoxField,
  DateField,
  FtInputField,
  SelectField,
  TextAreaField,
} from "shared/ui/ecosystems/forms";
import { DockField } from "entities/docks";
import { mediaPhone } from "shared/ui/styles/media";
import {
  createDateSelector,
  createDurationSelector,
  createTimeSelector,
} from "entities/reservations";
import { CheckboxWithQ, QTooltip } from "shared/ui/tooltip";

import { dateTimeModel } from "../model/add-reservatoin.model";

export function ReservationFormFields(props: {
  isLOADisabled: boolean;
}): JSX.Element {
  return (
    <>
      <FormFields>
        <FieldsGroup>
          <DockField name="dock" label="Select dock *" />
          <DurationSelector
            renderElement={(props) => (
              <SelectField label="Duration" name="duration" {...props} />
            )}
          />
          <DateSelector
            renderElement={(props) => (
              <DateField name="date" label="Date" {...props} />
            )}
          />
        </FieldsGroup>
        <FieldsGroup>
          <Loa
            disabled={props.isLOADisabled}
            name="loa"
            label="Enter LOA *"
            min={0}
            maxLength={3}
          />
          <TimeSelector
            renderElement={(props) => (
              <SelectField label="Time" name="time" {...props} />
            )}
          />
        </FieldsGroup>
        <CheckboxWithQ>
          <CheckBoxField name="isSuperOverride">Super override</CheckBoxField>
          <QTooltip>
            Warning: You are about to make an override. Please be sure that
            there is enough dock space available during this time or that a
            rafting arrangement has been made.
          </QTooltip>
        </CheckboxWithQ>
      </FormFields>
      <Comment>
        <TextAreaField
          name="comment"
          label="Write a comment about reservation"
          maxLength={1000}
          minRows={3}
          maxRows={3}
        />
      </Comment>
    </>
  );
}

const DurationSelector = createDurationSelector(dateTimeModel);
const DateSelector = createDateSelector(dateTimeModel);
const TimeSelector = createTimeSelector(dateTimeModel);

const Loa = styled(FtInputField)``;

const FormFields = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }

  &:not(:first-child) {
    margin-bottom: 10px;
  }
`;

const FieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
`;

const Comment = styled(Box)`
  margin-top: 10px;
  > .MuiFormControl-root {
    width: 100%;
  }
`;
