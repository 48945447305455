import { createGate } from "effector-react";
import { attach, combine, createEvent, createStore } from "effector";
import { getListFromApi, getManagerCollection } from "shared/api";
import { Manager } from "shared/api/types";
import { pagination, spreadListDataData } from "shared/lib/effector-paginatoin";

import { newManagerCreated } from "@manager-app/features/manage-managers/add";
import { managerDeleted } from "@manager-app/features/manage-managers/delete";
import { managerEdited } from "@manager-app/features/manage-managers/edit";

interface Filters {
  itemsPerPage: number;
  keyword: string | null;
}

export const pageGate = createGate();

export const $managersList = createStore<Manager[]>([]);
export const $managersTotalCount = createStore(0);

export const $currentPage = createStore(1);
export const $itemsPerPage = createStore(10);
export const $searchQuery = createStore<string | null>(null);

export const pageChanged = createEvent<number>();
export const itemsPerPageChanged = createEvent<number>();
export const searchQueryChanged = createEvent<string>();

const $filters = combine({
  itemsPerPage: $itemsPerPage,
  keyword: $searchQuery,
});

$currentPage.on(pageChanged, (_, page) => page);
$searchQuery.on(searchQueryChanged, (_, value) => value).reset(pageGate.close);
$itemsPerPage.on(itemsPerPageChanged, (_, value) => value);

const getManagerCollectionFx = attach({
  // @ts-ignore
  effect: getListFromApi(getManagerCollection),
  mapParams: (query: Filters & { page: number }) => ({
    query,
  }),
});

spreadListDataData({
  $items: $managersList,
  $totalCount: $managersTotalCount,
  effect: getManagerCollectionFx,
});

pagination({
  $page: $currentPage,
  $filters: $filters,
  // @ts-ignore
  effect: getManagerCollectionFx,
  gate: pageGate,
  reloadTrigger: [newManagerCreated, managerDeleted, managerEdited],
});
