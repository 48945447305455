import styled from "styled-components";
import { useStore } from "effector-react";

import {
  FormError,
  InputField,
  LettersField,
  PhoneField,
  SubmitButton,
} from "shared/ui/ecosystems/forms";

import { Form } from "shared/lib/form";

import { addUserApiErrorsByStatuses } from "shared/api";

import { mediaPhone } from "shared/ui/styles/media";

import { $formSubmitting, $serverErrorStatus, newCustomerForm } from "../model";
import { FormFields } from "./new-customer-modal";

export function NewCustomerForm(): JSX.Element {
  const isSubmitting = useStore($formSubmitting);
  const errorStatus = useStore($serverErrorStatus);
  const error =
    (errorStatus && addUserApiErrorsByStatuses[errorStatus]) ?? "Unknown error";

  return (
    <Root>
      {errorStatus && <FormError>{error}</FormError>}
      <Form form={newCustomerForm}>
        <Content>
          <FormFields>
            <LettersField
              name="firstName"
              type="string"
              label="First name *"
              maxLength={35}
            />
            <LettersField
              name="lastName"
              type="string"
              label="Last Name *"
              maxLength={35}
            />
            <InputField name="email" type="email" label="Email *" />
            <PhoneField name="phone" label="Phone Number" />
          </FormFields>
        </Content>
        <Actions>
          <SubmitButton isSubmitting={isSubmitting}>Next step</SubmitButton>
        </Actions>
      </Form>
    </Root>
  );
}

const Content = styled.div``;

const Actions = styled.div`
  display: flex;
  justify-content: center;

  ${SubmitButton}:only-child {
    width: 322px;

    ${mediaPhone} {
      width: 100%;
    }
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${Content} {
    margin-bottom: 32px;
  }
`;
