import styled from "styled-components";

import { DocksIcon } from "shared/ui/icons";
import { SmallModalTemplate, ModalIcon } from "shared/ui/modals";
import { useStore } from "effector-react";

import React from "react";

import { LinkButton } from "shared/ui/button";

import { $createdDock } from "../model/add-dock-form.model";
import { $editedDock } from "../model/edit-dock-form.model";

export function DockAddSuccess(): JSX.Element | null {
  const createdDock = useStore($createdDock);

  if (!createdDock) {
    return null;
  }

  return (
    <SuccessModalTemplate title="Dock creating">
      Dock <Dock>{createdDock.name}</Dock> was successfully created
    </SuccessModalTemplate>
  );
}

export function DockEditSuccess(): JSX.Element | null {
  const createdDock = useStore($editedDock);

  if (!createdDock) {
    return null;
  }

  return (
    <SuccessModalTemplate title="Dock saving">
      <div>
        Changes for <Dock>{createdDock.name}</Dock> was successfully saved
      </div>
    </SuccessModalTemplate>
  );
}

function SuccessModalTemplate(props: {
  title: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <SmallModalTemplate
      isOpened
      renderTitle={() => props.title}
      renderContent={() => (
        <>
          <ModalIcon as={DocksIcon} />
          {props.children}
        </>
      )}
      renderActions={() => (
        <Actions>
          <LinkButton to="/docks-list">Back to docks</LinkButton>
        </Actions>
      )}
    />
  );
}

const Dock = styled.span`
  font-size: 16px;
  line-height: 18px;
  color: #0a1128;
`;

const Actions = styled.div`
  text-align: center;
`;
