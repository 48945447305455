import styled from "styled-components";
import { useUnit } from "effector-react";

import { CheckIcon } from "shared/ui/icons";
import { Dock } from "entities/docks";

import {
  addonLineClicked,
  $addons,
  isCharterClicked,
  $isCharter,
} from "../models/addon-list-selector-model";

type Addons = Pick<Dock, "addons">;

type Props = {
  className?: string;
  dockAddons: Addons;
  disabled?: boolean;
};

export function AddonsListSelector(props: Props): JSX.Element {
  const [addons, isCharter] = useUnit([$addons, $isCharter]);
  return (
    <Content>
      {props?.dockAddons?.length > 0 && !props.disabled && (
        <>
          <Title>Select Add-ons</Title>
          <AddonsList>
            {props.dockAddons.map((addon) => (
              <Line onClick={() => addonLineClicked(addon["@id"])}>
                <Info>
                  <AddonTitle>{addon.addon.name}</AddonTitle>
                  <Description>{addon.description}</Description>
                </Info>
                <Check isChecked={addons.includes(addon["@id"])} />
              </Line>
            ))}
          </AddonsList>
        </>
      )}
      <>
        <Title>Setting</Title>
        {!props.disabled && (
          <Line onClick={() => isCharterClicked()}>
            <Info>
              <CharterTitle>Is this a charter?</CharterTitle>
              <Description>
                Eu in elementum vestibulum gravida eu purus ornare.
              </Description>
            </Info>
            <Check isChecked={isCharter} />
          </Line>
        )}
      </>
    </Content>
  );
}

const AddonTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
`;

const CharterTitle = styled(AddonTitle)`
  color: #5e88ef;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Check = styled(CheckIcon)<{ isChecked: boolean }>`
  opacity: ${(props) => (props.isChecked ? "1" : "0")};
  width: 24px;
  height: 24px;
`;

const Line = styled.div`
  display: flex;
  gap: 44px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #f7f9fc;
  border: 1px solid #e7ecf7;
  border-radius: 12px;
  padding: 12px 16px;
`;

const AddonsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 6px 0;
  /* ${Line} {
    padding: 12px 16px;
  }*/
`;
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
`;
