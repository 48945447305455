import { attach, createEvent, sample } from "effector";

import { deleteDockItem } from "shared/api";

export const deleteDock = createEvent<number>();
export const dockDeleted = createEvent<number>();

const deleteDockFx = attach({
  effect: deleteDockItem,
  // @ts-ignore
  mapParams: (id: number) => ({ path: { id } }),
});

sample({
  clock: deleteDock,
  target: deleteDockFx,
});

sample({
  clock: deleteDockFx.done,
  fn: ({ params }) => params,
  target: dockDeleted,
});
